import { Country } from "../../model/library/Country";
import { IconsEnum } from "../constant/IconsEnum";

export function getFlagUrl(str: string): string {
    return IconsEnum.imageUrl + "flags/" + str.toLowerCase() + ".svg#" + str.toLowerCase();        
}

export function getCountryDescription(iso2:string, fullList?:Country[]) : string {
    if (!iso2 || iso2==='') {
        return '';
    }

    if (!fullList) {
        return iso2;
    }

    for (let i=0; i< fullList.length;i++) {
        if (fullList[i].iso2===iso2) {
            if (fullList[i].name && fullList[i].name!=='') {
                return fullList[i].name.toLowerCase();
            } else {
                return iso2;
            }
        }
    }

    return iso2;
}