export class OrganizationUnit {
    public id!:number;
    public name!:string;
    public code!:string;
    public apiKey!:string;
    public defaultSearchScore!:number;
    public description!:string;
    public parent!:OrganizationUnit;
    public deleted!:boolean;

    public creationDate!:string;
	public createdBy!:string;
	public updatedDate!:string;
	public updatedBy!:string;
}