import React from "react";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { formatMessage } from "../../common/translate/Translate";
import { Alert } from "../../model/alerts/Alert";
import { Comment } from "../../model/alerts/Comment";
import { addCommentToAlert } from "../../store/alerts/action";
import { Request } from "../../model/common/Request";
import { RowCardComment } from "../../components/comment/RowCardComment";

interface IntProps {
    alert?:Alert,
}

interface IntState {
    comment:string
}

export class AlertCommentPopup extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {
            comment : ''
        }
    }

     public render() {    
        return (
                <React.Fragment>                       
                    <div className="d-modal__view js-modal-view">                       
                        <textarea
                                className="o-input"
                                name="comment"                                                               
                                placeholder={formatMessage('comment_text')}                                    
                                value={this.state.comment}
                                onChange={this.setCommment}                          
                                style={{marginLeft: '5px',marginRight:'5px',marginTop:'18px', width:'99%'}}              
                            /> 
                        <div style={{display : 'flex', marginLeft:'2px', marginRight:'2px'}}>
                            <button className="m-button m-button--small-margin-bottom" style={{margin: '5px'}} onClick={this.addComment}>
                                {formatMessage('button_save')}
                            </button>                                
                        </div>
                    </div>
                    <div style={{overflowY:'auto', overflowX:'hidden', height:'38rem'}}>
                            {this.props.alert && this.props.alert.comments && this.props.alert.comments.map((comment, j) => { 
                                    return (
                                        <RowCardComment comment={comment}/>
                                    );
                                }
                            )}
                            {(!this.props.alert || !this.props.alert.comments || this.props.alert.comments.length===0) &&
                                <label className="o-label">{formatMessage('comment_nodata')}</label>  
                            }

                     </div>
                </React.Fragment>
        );
    }

    private setCommment = (event:any) => {
        this.setState({
            comment : event.target.value
        })
    }

    private addComment = (event:any) => {
        if (this.props.alert) {
            let comment:Comment = new Comment();
            comment.comment = this.state.comment;

            let request:Request = new Request();
            request.alert = this.props.alert;
            request.comment = comment;

            addCommentToAlert(request);     
            
            this.setState({
                comment : ''
            })
        }
        
    }
}