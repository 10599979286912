import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";
import { Response } from "../../model/common/Response";
import { Constants } from "./constant";


export const getAllCountries = () => {
    
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.allCountries,  axioConf).then((response: AxiosResponse<Response>) => { 
        return store.dispatch({
            payload: response.data,
            type: Constants.FULLIST
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
    })
}