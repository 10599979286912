import { DataSets } from "../dataset/DataSets";

export class MatchingRuleScoring {

    public id!:number;	
	public name!:string;
	public description!:string;
	public score!:string;	
	public status!:string;
	public priority!:number;
	public dataId!:string;

	public dataset!:DataSets;

}