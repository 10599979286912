export class MatchingLocation {

    public street!:String;
	public city!:String;	
	public country!:String;
	
	public streetNumber!:String;	
	public streetName!:String;

	public poBox!:String;	
	public zipCode!:String;	
	public state!:String;	
	public normalized!:String;
}