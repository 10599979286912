import { WatchListPerson } from "../../model/persons/WatchListPerson"
import { Constants } from "./constant"
import { WatchlistStore } from "./type"


const init: WatchlistStore = {
    currentWatchlistPerson : new WatchListPerson()
} 
    
export const watchlistPersonReducer = (state: WatchlistStore = init, action: any): WatchlistStore => {    
    switch (action.type) {      
        case Constants.SINGLEWATCHLISTPERSON:          
            return {
				...state,
				currentWatchlistPerson: action.payload
            } 
        
        default:
            return {
				...state,
			}
    }
}