import { LocalStore } from "./type"

const init: LocalStore = {
    local : 'en'
} 

export const localStoreReducer = (state: LocalStore = init, action: any): LocalStore => {    
    switch (action.type) {        
        default:
            return {
				...state,
				local: action.payload
			}
    }
}