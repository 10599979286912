import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";
import { Request } from "../../model/common/Request";
import { Response } from "../../model/common/Response";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { Constants } from "./constant";

export const getAllMatchingRules = () => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.getAllMatchingRules, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.MATCHINGRULELISTS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const updateMatchingRules = (request:Request) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.updateMatchingRules, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.MATCHINGRULEUPDATE
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const deleteMatchingRules = (matchingRuleId:string) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.delete(URLConstants.deleteMatchingRules + matchingRuleId, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.MATCHINGRULEDELETE
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}