import { combineReducers, createStore } from 'redux';
import { AlertStore } from './alerts/type';
import { isLoadingReducer } from './loader/reducer';
import { LoadingDisplayStore } from './loader/type';
import { localStoreReducer } from './locale/reducer';
import { LocalStore } from './locale/type';
import { loginResponseReducer } from './login/reducer';
import { alertResponseReducer } from './alerts/reducer';
import { UserLoginStore } from './login/type';
import { SavedSearchStore } from './savedsearch/type';
import { savedSearchResponseReducer } from './savedsearch/reducer';
import { UserStore } from './user/type';
import { userReducer } from './user/reducer';
import { NotificationDisplayStore } from './notification/type';
import { notificationDisplayReducer } from './notification/reducer';
import { PersonStore } from './customer/type';
import { personResponseReducer } from './customer/reducer';
import { DatasetStore } from './dataset/type';
import { datasetResponseReducer } from './dataset/reducer';
import { GroupStore } from './groups/type';
import { groupReducer } from './groups/reducer';
import { RoleStore } from './role/type';
import { roleReducer } from './role/reducer';
import { OrganizationUnitStore } from './orgunit/type';
import { organizationUnitReducer } from './orgunit/reducer';
import { AuditStore } from './audit/type';
import { auditResponseReducer } from './audit/reducer';
import { FileStore } from './files/type';
import { fileReducer } from './files/reducer';
import { SearchDatasetStore } from './searchdataset/type';
import { searchDatasetReducer } from './searchdataset/reducer';
import { RiskStore } from './risk/type';
import { riskResponseReducer } from './risk/reducer';
import { TransactionStore } from './transactions/type';
import { transactionReducer } from './transactions/reducer';
import { AccountStore } from './account/type';
import { accountResponseReducer } from './account/reducer';
import { WatchlistStore } from './watchlist/type';
import { watchlistPersonReducer } from './watchlist/reducer';
import { JobStore } from './scheduler/type';
import { jobResponseReducer } from './scheduler/reducer';
import { MatchingRuleStore } from './screening/type';
import { matchingRuleReducer } from './screening/reducer';
import { WorkflowStore } from './workflow/type';
import { workflowReducer } from './workflow/reducer';
import { CountryStore } from './country/type';
import { countryResponseReducer } from './country/reducer';
import { SearchScreenaDatasetStore } from './search/type';
import { searchScreenaDatasetReducer } from './search/reducer';

export interface IRootState {
    isLoadingStore:LoadingDisplayStore,
    localStore:LocalStore,
    loginStore:UserLoginStore,
    alertStore:AlertStore,
    savedSearchStore:SavedSearchStore,
    userStore:UserStore,
    notificationDisplayStore:NotificationDisplayStore,
    personStore:PersonStore,
    datasetStore:DatasetStore,
    groupStore:GroupStore,
    roleStore:RoleStore,
    organizationUnitStore:OrganizationUnitStore,
    auditStore:AuditStore,
    fileStore:FileStore,
    searchDatasetStore:SearchDatasetStore,
    riskStore:RiskStore,
    transactionStore:TransactionStore,
    accountStore:AccountStore,
    watchlistStore: WatchlistStore,
    jobStore:JobStore,
    matchingRuleStore:MatchingRuleStore,
    workflowStore:WorkflowStore,
    countryStore:CountryStore,
    searchScreenaDatasetStore:SearchScreenaDatasetStore
}

export const store = createStore<IRootState, any, any, any>(
    combineReducers({
        localStore: localStoreReducer,
        isLoadingStore: isLoadingReducer,
        loginStore: loginResponseReducer,
        alertStore: alertResponseReducer,
        savedSearchStore: savedSearchResponseReducer,
        userStore: userReducer,
        notificationDisplayStore: notificationDisplayReducer,
        personStore: personResponseReducer,
        datasetStore:datasetResponseReducer,
        groupStore:groupReducer,
        roleStore:roleReducer,
        organizationUnitStore: organizationUnitReducer,
        auditStore:auditResponseReducer,
        fileStore:fileReducer,
        searchDatasetStore:searchDatasetReducer,
        riskStore:riskResponseReducer,
        transactionStore: transactionReducer,
        accountStore: accountResponseReducer,
        watchlistStore: watchlistPersonReducer,
        jobStore:jobResponseReducer,
        matchingRuleStore:matchingRuleReducer,
        workflowStore : workflowReducer,
        countryStore : countryResponseReducer,
        searchScreenaDatasetStore: searchScreenaDatasetReducer
    })
);
export default store;