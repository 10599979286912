export class Role {
    public id!:number;

    public name!:string;
	public description!:string;
	
	public creationDate!:string;
	public createdBy!:string;
	public updatedDate!:string;
	public updatedBy!:string;
	public deleted!:boolean;
	
	public permissions!:string[];
}