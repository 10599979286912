export enum Constants {
    FULLLISTOFUSERS = 'FULLLISTOFUSERS',
    SINGLEUSER = 'SINGLEUSER',
    LISTOFUSERS = 'LISTOFUSERS',
    COUNTOFUSERS = 'COUNTOFUSERS',
    CREATEUSER = 'CREATEUSER',
    USERPREFERENCES = 'USERPREFERENCES',
    USERDASHBOARDPREFERENCES = 'USERDASHBOARDPREFERENCES',
    USERDASHBOARDPREFERENCESUPDATE = 'USERDASHBOARDPREFERENCESUPDATE',
    DELETEUSER = 'DELETEUSER',
}