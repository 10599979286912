

export class WorkflowStatus {

    public id!:number;
	
	public code!:string;
	public description!:string;
	
	public actions!:string[];
	
	public nextSteps!:WorkflowStatus[];

	public closeStatus!:boolean;

	public assignToUser!:boolean;
	public assignToGroup!:boolean;
	public keepCurrentStatus!:boolean;

	public creationDate!:string;
	public createdBy!:string;
	public updateDate!:string;
	public updatedBy!:string;

}