export class Permissions {

    public static ADMINISTRATION:string = 'administration';
    public static AUDITS:string = 'audits';
    public static SCREENA_AUDITS:string = 'screenaaudits';
    public static SEGRATE_AUDITS:string = 'segrateaudits';
    
    public static DATASETS:string = 'datasets';
    public static RISKS:string = 'risks';
    public static GENERAL:string = 'general';
    public static ALERTS:string = 'alerts';
    public static SEARCH:string = 'search';

    /**Administration Permissions */
    public static USER_MANAGEMENT:string = 'UserManagement';
    public static ROLE_MANAGEMENT:string = 'RoleManagement';
    public static GROUP_MANAGEMENT:string = 'GroupManagement';
    public static ORGANIZATIONUNIT_MANAGEMENT:string = 'OrganizationUnitManagement';
    public static DATASET_MANAGEMENT:string = 'DatasetManagement';
    public static SCHEDULER_MANAGEMENT:string = 'SchedulerManagement';
    public static AUDIT_MANAGEMENT:string = 'AuditManagement';
    public static RISK_MANAGEMENT:string = 'RiskManagement';
    public static SCREENING_MANAGEMENT:string = 'ScreeningManagement';
    public static WORKFLOW_MANAGEMENT:string = 'WorlkflowManagement';

    public static optionPermissions:{value:string;label:string;}[] = [
        {value: Permissions.ADMINISTRATION, label: "Administration"},
        {value: Permissions.AUDITS, label: "Audit"},
        {value: Permissions.SCREENA_AUDITS, label: "Screena Audit"},
        {value: Permissions.DATASETS, label: "Datasets"},
        {value: Permissions.RISKS, label: "Risks"},
        {value: Permissions.GENERAL, label: "General"},
        {value: Permissions.ALERTS, label: "Alerts"},
        {value: Permissions.SEARCH, label: "Search"},

        {value: Permissions.USER_MANAGEMENT, label: "User Management"},
        {value: Permissions.ROLE_MANAGEMENT, label: "Role Management"},
        {value: Permissions.GROUP_MANAGEMENT, label: "Group Management"},
        {value: Permissions.ORGANIZATIONUNIT_MANAGEMENT, label: "Organization Unit Management"},
        {value: Permissions.DATASET_MANAGEMENT, label: "Dataset Management"},
        {value: Permissions.SCHEDULER_MANAGEMENT, label: "Scheduler Management"},
        {value: Permissions.AUDIT_MANAGEMENT, label: "Audit Management"},
        {value: Permissions.RISK_MANAGEMENT, label: "Risk Management"},
        {value: Permissions.SCREENING_MANAGEMENT, label: "Screening Management"},
        {value: Permissions.WORKFLOW_MANAGEMENT, label: "Workflow Management"},
    ];
}