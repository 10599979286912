import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";
import { formatMessage } from "../../common/translate/Translate";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Request } from "../../model/common/Request";
import { Response } from "../../model/common/Response";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { showNotification } from "../notification/action";
import { Constants } from "./constant";


export const getPaginatedJobs = (request:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.schedulerList, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getTotalAmountOfJobs = (request:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.schedulerCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.TOTALAMOUNTOFJOBS
        });        
    })
    .catch((error: AxiosError<String>) => {  
        manageAxiosError(error);      
        stopLoadingStatus();
    })
}

export const createJob = (request:Request, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.schedulerCreate, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        showNotification('', formatMessage('job_action_created'), null, null) ;

        if (paginationRequest) {
            getPaginatedJobs(paginationRequest);
            getTotalAmountOfJobs(paginationRequest);
        }
        
        return store.dispatch({
            payload: response.data,
            type: Constants.CREATEJOB
        });        
    })
    .catch((error: AxiosError<String>) => {   
        manageAxiosError(error);     
        stopLoadingStatus();
    })
}

export const updateJob = (request:Request, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.schedulerUpdate, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        showNotification('', formatMessage('job_action_updated'), null, null) ;

        if (paginationRequest) {
            getPaginatedJobs(paginationRequest);
            getTotalAmountOfJobs(paginationRequest);
        }
        
        return store.dispatch({
            payload: response.data,
            type: Constants.UPDATEJOB
        });        
    })
    .catch((error: AxiosError<String>) => {  
        manageAxiosError(error);      
        stopLoadingStatus();
    })
}

export const deleteJob = (jobId:string, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.delete(URLConstants.schedulerDelete + jobId, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        showNotification('', formatMessage('job_action_deleted'), null, null) ;   

        if (paginationRequest) {
            getPaginatedJobs(paginationRequest);
            getTotalAmountOfJobs(paginationRequest);
        }

        return store.dispatch({
            payload: response.data,
            type: Constants.DELETEJOB
        });        
    })
    .catch((error: AxiosError<String>) => {        
        stopLoadingStatus();
        manageAxiosError(error);
    })
}

export const startJob = (jobId:string, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.schedulerStart + jobId, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        showNotification('', formatMessage('job_action_started'), null, null) ;   

        if (paginationRequest) {
            getPaginatedJobs(paginationRequest);
            getTotalAmountOfJobs(paginationRequest);
        }

        return store.dispatch({
            payload: response.data,
            type: Constants.STARTJOB
        });        
    })
    .catch((error: AxiosError<String>) => {        
        stopLoadingStatus();
        manageAxiosError(error);
    })
}

