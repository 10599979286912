import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { Request } from "../../model/common/Request";
import { createDatasetFolders, updateDatasetFolders } from "../../store/dataset/action";
import Select, { ActionMeta, OptionsType } from 'react-select';
import { Group } from "../../model/user/Group";
import { OrganizationUnit } from "../../model/user/OrganizationUnit";
import { User } from "../../model/user/User";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { DataSetsFolder } from "../../model/dataset/DataSetsFolder";

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,
    datasetfolder?:DataSetsFolder,
    create?:boolean,
    closePopup?:() => void,
    fullGroupList?:Group[],
    fullUserList?:User[],
    fullOrganizationList?:OrganizationUnit[],
    paginationRequest?:PaginationRequest,
    currentFolderId?:number
}

interface IntState {
    datasetName:string,  
    datasetDescription:string,  
    datasetUser:number[],
    datasetGroup:number[],
    datasetOrgUnit:number[],
    errors:{
        datasetName:string,
        datasetDescription:string
    }
}

class DatasetFolderDetailsPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {
            datasetName:'',   
            datasetDescription:'',
            datasetUser:[],
            datasetGroup:[],
            datasetOrgUnit:[],
            errors:{
                datasetName:'',
                datasetDescription:''
            }       
        }
    }

    public componentWillReceiveProps(nextProps: IntProps) {
        if (nextProps.create) {
            this.setState({
                datasetName : '',
                datasetDescription : '',

                errors:{
                    datasetName:'',
                    datasetDescription:''
                }  
            })
        } else if (nextProps.datasetfolder) {                
          let selectedGroup:number[] = [];
          if (nextProps.datasetfolder.groups) {
              for (var group of nextProps.datasetfolder.groups) {
                  selectedGroup.push(group.id);
              }
          }   
            
          let selectedUser:number[] = [];
          if (nextProps.datasetfolder.users) {
              for (var user of nextProps.datasetfolder.users) {
                  selectedUser.push(user.id);
              }
          } 

          let selectedOrgunit:number[] = [];
          if (nextProps.datasetfolder.organizations) {
              for (var orgunit of nextProps.datasetfolder.organizations) {
                  selectedOrgunit.push(orgunit.id);
              }
          } 

          this.setState({
                datasetName : nextProps.datasetfolder.label,
                datasetDescription : nextProps.datasetfolder.description,
              
                datasetGroup : selectedGroup,
                datasetUser : selectedUser,
                datasetOrgUnit : selectedOrgunit,

                errors:{
                    datasetName:'',
                    datasetDescription:''
                }  
          })
        }
           
    }

    public render() {    
        let optionGroup:{value: string;label: string;}[] = [];
        let selectedGroup:{value: string;label: string;}[] = [];
        if (this.props.fullGroupList) {
            let i:number = 0;
            for (var group of this.props.fullGroupList) {
                optionGroup.push(
                    {
                        value : String(group.id),
                        label : group.name
                    }
                )

                for (var usergroupid of this.state.datasetGroup) {
                    if (usergroupid===group.id) {
                        selectedGroup.push (optionGroup[i]);
                    }
                }

                i++;
            }
        }

        let optionUser:{value:string;label:string;}[] = [];
        let selectedUser:{value: string;label: string;}[] = [];
        if (this.props.fullUserList) {
            let i:number = 0;
            for (var user of this.props.fullUserList) {
                optionUser.push(
                    {
                        value : String(user.id),
                        label : user.userName
                    }
                )

                for (var datasetuserid of this.state.datasetUser) {
                    if (datasetuserid===user.id) {
                        selectedUser.push (optionUser[i]);
                    }
                }

                i++;
            }
        }

        let optionOrgUnit:{value:string;label:string;}[] = [];
        let selectedOrgunit:{value: string;label: string;}[] = [];
        if (this.props.fullOrganizationList) {
            let i:number = 0;
            for (var orgunit of this.props.fullOrganizationList) {                
                optionOrgUnit.push(
                    {
                        value : String(orgunit.id),
                        label : orgunit.name
                    }
                )

                for (var datasetorgunitid of this.state.datasetOrgUnit) {
                    if (datasetorgunitid===orgunit.id) {
                        selectedOrgunit.push (optionOrgUnit[i]);
                    }
                }

                i++;
            }
        }

        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "d-modal" : "c-popover c-lc-popover js-lc-context"} style={{ width: "50rem", height: "30rem" }}>
                    <div className="d-modal__view js-modal-view">
                        <div className="d-modal__head">
                            <legend className="d-modal__title">
                                {formatMessage('alert_details_information_title')}
                            </legend>
                            <div>                                
                                <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                    <svg className="o-icon o-icon--close o-icon--prepended">
                                        <title>{IconsEnum.CLOSE.title}</title>
                                        <use href={IconsEnum.CLOSE.url} />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="d-modal__view js-modal-view" style={{ margin: "1rem" }}>
                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_name')}
                                </label>
                                <input
                                    className={this.state.errors && this.state.errors["datasetName"]? "o-input error" : "o-input"}
                                    name="datasetName"
                                    placeholder={formatMessage('common_name')}
                                    value={this.state.datasetName}
                                    onChange={this.changeDatasetName}
                                    style={{ marginTop: '2px' }}
                                />
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_description')}
                                </label>
                                <input
                                    className={this.state.errors && this.state.errors["datasetDescription"]? "o-input error" : "o-input"}
                                    name="datasetDescription"
                                    placeholder={formatMessage('common_description')}
                                    value={this.state.datasetDescription}
                                    onChange={this.changeDatasetDescription}
                                    style={{ marginTop: '2px' }}
                                />
                            </div>

                            <label className="o-label" style={{marginBottom: '5px'}} >{formatMessage('common_groups')}</label>
                            <Select name="datasetGroups"                                                                                                                     
                                        className="basic-multi-select"
                                        value={selectedGroup}
                                        options={optionGroup}
                                        classNamePrefix="select"
                                        onChange={this.changeGroupsSelect}
                                        isMulti
                                />

                            <label className="o-label" style={{marginBottom: '5px'}} >{formatMessage('common_roles')}</label>
                            <Select options={optionUser}
                                        name="datasetUsers" 
                                        value={selectedUser}                                                                             
                                        isMulti
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={this.changeUserSelect}
                                />

                            <label className="o-label" style={{marginBottom: '5px'}} >{formatMessage('common_organizationunit')}</label>
                            <Select options={optionOrgUnit}
                                        value={selectedOrgunit}
                                        name="datasetOrgunits"                                                                              
                                        isMulti
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={this.changeOrgUnitSelect}
                                />
                        </div>

                        <div style={{display : 'flex'}}>
                                <button className="m-button m-button--small-margin-bottom" onClick={this.props.closePopup} style={{margin: '5px'}}>
                                    {formatMessage('button_cancel')}
                                </button> 
                                
                                <button className="m-button m-button--small-margin-bottom" onClick={this.saveDataset} style={{margin: '5px'}}>
                                    {formatMessage('button_save')}
                                </button> 
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private changeGroupsSelect = (values: OptionsType<{value: string;label: string;}>, actionMeta: ActionMeta<{value: string;label: string;}>):void => {        
        let groups:number[]=[]

        if (values) {
            for (var value of values) {
                groups.push(Number(value.value));
            }
        }

        this.setState({                        
            ...this.state,  
            datasetGroup : groups                
        });
    }

    private changeUserSelect = (values: OptionsType<{value: string;label: string;}>, actionMeta: ActionMeta<{value: string;label: string;}>):void => {
        
        let roles:number[]=[]

        if (values) {
            for (var value of values) {
                roles.push(Number(value.value));
            }
        }

        this.setState({                        
            ...this.state,  
            datasetUser : roles                
        });
    }

    private changeOrgUnitSelect = (values: OptionsType<{value: string;label: string;}>, actionMeta: ActionMeta<{value: string;label: string;}>):void => {
        
        let orgunits:number[]=[]

        if (values) {
            for (var value of values) {
                orgunits.push(Number(value.value));
            }
        }

        this.setState({                        
            ...this.state,  
            datasetOrgUnit : orgunits                
        });
    }

    private changeDatasetName = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            datasetName: event.target.value
        });
    }

    private changeDatasetDescription = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            datasetDescription: event.target.value
        });
    }

    private saveDataset = () : void => {
        let request:Request = new Request();
        let dataset:DataSetsFolder = new DataSetsFolder();

        if (this.props.datasetfolder) {
            dataset = this.props.datasetfolder;
        }
        
        let errors = {
            datasetName  : '',
            datasetDescription : ''
        };

        let noError:boolean = true;

        if (!this.state.datasetName || this.state.datasetName==='') {
            errors.datasetName = 'No dataset name';
            noError = false;
        }

        if (!this.state.datasetDescription || this.state.datasetDescription==='') {
            errors.datasetDescription = 'No dataset description';
            noError = false;
        }

        if (!noError) {
            this.setState({
                ...this.state, 
                errors:errors       
           })
   
           return;
        }

        dataset.label = this.state.datasetName;
        dataset.description = this.state.datasetDescription;
       
        if (this.props.currentFolderId && this.props.currentFolderId!==-1) {
            dataset.parentId = this.props.currentFolderId;
        }

        // Groups
        dataset.groups = [];
        for (var groupid of this.state.datasetGroup) {
            let group:Group = new Group();
            group.id = groupid;
            dataset.groups.push(group);
        }

        dataset.organizations = [];
        for (var orgunitid of this.state.datasetOrgUnit) {
            let orgunit:OrganizationUnit = new OrganizationUnit();
            orgunit.id = orgunitid;
            dataset.organizations.push(orgunit);
        }

        dataset.users = [];
        for (var userid of this.state.datasetUser) {
            let user:User = new User();
            user.id = userid;
            dataset.users.push(user);
        }

        request.datasetFolder = dataset;         

        if (this.props.create) {               
            createDatasetFolders(request);
        } else if (this.props.datasetfolder) {
            updateDatasetFolders(request);
        }

        if (this.props.closePopup) {
            this.props.closePopup();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    fullGroupList : state.groupStore.fullListOfGroups,
    fullUserList : state.userStore.fullListOfUsers,
    fullOrganizationList : state.organizationUnitStore.fullListOfOrganizationUnits
})

export const DatasetFolderDetailsPopup = connect(mapStateToProps, null)(DatasetFolderDetailsPopupClass);