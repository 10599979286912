import React from "react";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { Comment } from "../../model/alerts/Comment";

interface IntProps {
    comment?:Comment
}

interface IntState {

}

export class RowCardComment extends React.Component<IntProps, IntState> {

    public render() {
        return (
            <div className={`entity-card js-horizontal-card`}>
                    <div className="entity-card__comment" style={{marginLeft:'2rem'}}>
                        <div>
                            <p  className="row-card-paragraph" style={{fontWeight: 'bold'}}>
                                {this.props.comment?this.props.comment.comment:''}
                            </p>                           
                        </div>                                            
                        <p className="row-card-paragraph">
                            <span className={"o-badge o-badge--small"}>
                                {this.props.comment && this.props.comment.createdBy}                               
                            </span>                      
                        </p>

                        <p className="row-card-paragraph">
                            <span className={"o-badge o-badge--small"}>                               
                                 {this.props.comment && this.props.comment.creationDate}  
                            </span>                      
                        </p>
                    </div>                    
                </div>  
        );
    }

}