import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Response } from "../../model/common/Response";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { Constants } from "./constant";



export const getTransactionsLinkedToPerson = (personId:string) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.transactionLinkedToPerson + personId, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.LISTOFTRXNS
        });        
    })
    .catch((error: AxiosError<String>) => {
        stopLoadingStatus();
        manageAxiosError(error);       
    })
}

export const getPaginatedTransactionsLinkedToPerson = (paginationRequest:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.transactionPaginatedLinkedToPerson, JSON.stringify(paginationRequest), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGELISTOFTRXNS
        });        
    })
    .catch((error: AxiosError<String>) => {
        stopLoadingStatus();
        manageAxiosError(error);
    })
}

export const getCountTransactionsLinkedToPerson = (paginationRequest:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.transactionCountLinkedToPerson , JSON.stringify(paginationRequest), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.COUNTOFTRXNS
        });        
    })
    .catch((error: AxiosError<String>) => {
        stopLoadingStatus();
        manageAxiosError(error);
    })
}