import { RiskValueMapping } from "./RiskValueMapping";

export class RiskMapping {

    public id!:number;
    public description!:string;
    public scenario!:string;
    public fieldName!:string;
    public filterFieldNames!:string[];
    public riskCategory!:string;
    public riskSubCategory!:string;
    public entityType!:string;
    public dataId!:string;
    public noValue!:boolean;
    public scenarioName!:string;
    public score!:number;
    public operation!:string;
    public executionOrder!:number;
    public profileName!:string;
    public riskValueMappingDataset!:string;

    public globalWeight!:number;
    public globalOperation!:number;

    public creationDate!:string;
	public createdBy!:string;
	public updateDate!:string;
	public updatedBy!:string;

    public riskValueMapping!:RiskValueMapping[];

}