import React from "react";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { capitalizeWords } from "../../common/helper/StringHelper";
import { MatchingName } from "../../model/screena/MatchingName";
import { formatMessage } from "../../common/translate/Translate";

interface IntProps {
    field?: string,
    values?: MatchingName[],
    value?: MatchingName,
    amountOnTarget?: number
}

interface IntState {
    open: boolean
}

export class MultipleAttributeDisplayMatchingName extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = ({
            open: false
        });
    }

    public render() {
        const addItems = [];

        if (!(this.props.values && this.props.values.length > 0)) {
            addItems.push(
                <div className="attribute body">
                    <span className="attribute label withvalue">
                        {this.props.field}
                    </span>
                    <span className="attribute value" />
                </div>
            );
        }

        if ((this.props.values && this.props.values.length > 0) || this.props.value) {
            if (this.props.values && this.state.open) {
                return (
                    <React.Fragment>
                        {this.props.values.map((elem, j) => {
                            let strValue: string = this.getFullNameForDisplay(elem);
                            return (
                                <React.Fragment>
                                    <div id={"div" + this.props.field + j} className="attribute body">
                                        <span id={"spanlbl" + this.props.field + j} className="attribute label withvalue">
                                            {j === 0 ? this.props.field : ''}
                                        </span>
                                        <span id={"spanvlue" + this.props.field + j} className="attribute value">
                                            {strValue}
                                        </span>
                                        {j === 0 && this.props.values && this.props.values.length > 1 &&
                                            <span id={"bdg" + this.props.field + j} className="badge" onClick={this.openAttribute}>
                                                {'-'}
                                            </span>
                                        }
                                    </div>
                                </React.Fragment>
                            );

                        })
                        }

                        {addItems}

                    </React.Fragment>

                );
            } else {

                let valueTodisplay: MatchingName;

                if (this.props.values) {
                    valueTodisplay = this.props.values[0];
                } else if (this.props.value) {
                    valueTodisplay = this.props.value;
                } else {
                    return;
                }

                return (
                    <React.Fragment>
                        {valueTodisplay.fullName && valueTodisplay.fullName.length > 0 &&
                            <div id={"div" + this.props.field} className="attribute body">
                                <span id={"spanlbl" + this.props.field} className="attribute label withvalue">
                                    {this.props.field}
                                </span>
                                <span id={"spanvlue" + this.props.field} className="attribute value">
                                    {valueTodisplay.fullName}
                                </span>
                                {this.props.values && this.props.values.length > 1 &&
                                    <span className="badge" onClick={this.openAttribute}>
                                        {'+' + String(this.props.values.length - 1)}
                                    </span>
                                }
                            </div>
                        }

                        {valueTodisplay.surname && valueTodisplay.surname.length > 0 &&
                            <div id={"div" + this.props.field} className="attribute body">
                                <span id={"spanlbl" + this.props.field} className="attribute label withvalue">
                                    {formatMessage('person_details_surname')}
                                </span>
                                <span id={"spanvlue" + this.props.field} className="attribute value">
                                    {valueTodisplay.surname}
                                </span>
                            </div>
                        }

                        {valueTodisplay.givenName && valueTodisplay.givenName.length > 0 &&
                            <div id={"div" + this.props.field} className="attribute body">
                                <span id={"spanlbl" + this.props.field} className="attribute label withvalue">
                                    {formatMessage('person_details_givenName')}
                                </span>
                                <span id={"spanvlue" + this.props.field} className="attribute value">
                                    {valueTodisplay.givenName}
                                </span>
                            </div>
                        }

                        {valueTodisplay.fatherName && valueTodisplay.fatherName.length > 0 &&
                            <div id={"div" + this.props.field} className="attribute body">
                                <span id={"spanlbl" + this.props.field} className="attribute label withvalue">
                                    {formatMessage('person_details_fatherName')}
                                </span>
                                <span id={"spanvlue" + this.props.field} className="attribute value">
                                    {valueTodisplay.fatherName}
                                </span>
                            </div>
                        }
                       
                    </React.Fragment>
                );
            }
        } else {
            return (
                <React.Fragment>
                    {addItems}
                </React.Fragment>
            );
        }

    }

    private openAttribute = () => {
        this.setState({
            open: !this.state.open
        });
    }

    private getFullNameForDisplay(name: MatchingName): string {
        if (name.fullName && name.fullName.length > 0) {
            return name.fullName;
        }

        let fullNameConcat: string = '';

        if (name.surname) {
            fullNameConcat += name.surname;
            fullNameConcat += ' ';
        }

        if (name.fatherName) {
            fullNameConcat += name.fatherName;
            fullNameConcat += ' ';
        }

        if (name.givenName) {
            fullNameConcat += name.givenName;
        }

        return capitalizeWords(fullNameConcat.trim());
    }

}