import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";
import { formatMessage } from "../../common/translate/Translate";
import { Request } from "../../model/common/Request";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { showNotification } from "../notification/action";
import { Constants } from "./constant";


export const getFullListOfWkfStatus = () => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.fullWorkflowList, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.WORKFLOWSTATUSLIST
        });        
    })
    .catch((error: AxiosError<String>) => {
        stopLoadingStatus();
        manageAxiosError(error);
    })
}

export const createWorkflowStatus = (request:Request) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.createWorkflowStatus, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();   
        showNotification('', formatMessage('workflow_created'), null, null) ;    
        getFullListOfWkfStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.CREATEWORKFLOWSTATUS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const updateWorkflowStatus = (request:Request) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.updateWorkflowStatus, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();   
        showNotification('', formatMessage('workflow_updated'), null, null) ;    
        getFullListOfWkfStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.UPDATEWORKFLOWSTATUS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const deleteWorkflowStatus = (workflowStatusId:string) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.delete(URLConstants.deleteWorkflowStatus + workflowStatusId, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();   
        showNotification('', formatMessage('workflow_deleted'), null, null) ;    
        getFullListOfWkfStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.DELETEWORKFLOWSTATUS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}