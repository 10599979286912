import { Constants } from "./constant"
import { NotificationDisplayStore } from "./type"

const init: NotificationDisplayStore = {
    message: '',
    loadingCurrent: '',
    loadingMax: '',
    time: '',
    variants:'',
    hide:true
}
    
export const notificationDisplayReducer = (state: NotificationDisplayStore = init, action: any): NotificationDisplayStore => { 
    switch (action.type) {
        case Constants.SHOW:
            console.log(action.payload);
            return {
				...state,
                message: action.payload.message,
                loadingCurrent: action.payload.loadingCurrent,
                loadingMax: action.payload.loadingMax,
                time: action.payload.time,
                variants: action.payload.variants,
                hide:action.payload.hide
            }
      
        default:
            return {
				...state,
			}
    }
}