export class MatchingAlgo {

    public type!:string;
    public nullMatch!:boolean;
    public active!:boolean;
    public permutation!:boolean;

    public entityTypes!:string[];
    public exclude!:string[];
	public optional!:boolean;

}