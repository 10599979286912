import { DataSets } from "../dataset/DataSets";

export class Account {

    public id!:number;
	
	public type!:string;
	public fullAccountNumber!:string;
	public bic!:string;
	public bankIban!:string;
	public accountNumber!:string;
	
	public branchCountry!:string;
	public branchCity!:string;
	
	public openingDate!:string;
	public closingDate!:string;
	
	public status!:string;
	public baseCurrency!:string;
	public bankIdentifier!:string;
	
	public dataId!:string;
	public dataset!:DataSets;

	public creationDate!:string;
	public createdBy!:string;
	public updateDate!:string;
	public updatedBy!:string;


}