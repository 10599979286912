export class SearchStatistics {

    public numberOfPeps!:number;
    public numberOfSanctions!:number;
    public numberOfWanteds!:number;
    public numberOfAll!:number;
    public numberOfOrganizations!:number;
    public numberOfIndividuals!:number;
    public numberOfUnknowns!:number;
    public numberOfVessels!:number;
    public foundDataset!:string[];

}