import { DataSets } from "../dataset/DataSets";

export class MatchingRuleDefinition {

    public id!:number;
	public name!:string;
	public permutation!:boolean;
	public truncation!:boolean;

	public nationalityAlgo!:string;
	public nationalityNull!:boolean;
	public nationalityOptional!:boolean;
	
	public placeOfBirthAlgo!:string;
	public placeOfBirthNull!:boolean;
	public placeOfBirthOptional!:boolean;
	
	public addressAlgo!:string;
	public addressNull!:boolean;
	public addressOptional!:boolean;

	public sexAlgo!:string;
	public sexNull!:boolean;
	public sexOptional!:boolean;

	public placeOfRegistryAlgo!:string;
	public placeOfRegistryNull!:boolean;
	public placeOfRegistryOptional!:boolean;

	public dateOfRegistryAlgo!:string;
	public dateOfRegistryNull!:boolean;
	public dateOfRegistryOptional!:boolean;
	
	public flagAlgo!:string;
	public flagNull!:boolean;
	public flagOptional!:boolean;
		
	public dateOfBuildAlgo!:string;
	public dateOfBuildNull!:boolean;
	public dateOfBuildOptional!:boolean;
	
	public legalStatusAlgo!:string;
	public legalStatusNull!:boolean;
	public legalStatusOptional!:boolean;

	public identificationDocumentAlgo!:string;
	public identificationDocumentNull!:boolean;
	public identificationDocumentOptional!:boolean;
	
	public minimumAmountOfOptional!:number;

	public dateOfBirthAlgo!:string;	
	public dateOfBirthNull!:boolean;
	public dateOfBirthOptional!:boolean;

	public treshold!:number;

	public targetDataSets!:DataSets[];

}