import { JobParam } from "./JobParam";

export class Job {
    public id!:number;
	
	public name!:string;
	public cronExpression!:string;
	public description!:string;
	public lastExecutionTime!:string;
	public nextExecutionTime!:string;
	
	public active!:boolean;
	
	public jobType!:string;

	public creationDate!:string;
	public createdBy!:string;
	public updatedDate!:string;	
	public updatedBy!:string;
	
	public jobParams!:JobParam[];
}