import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'

import { formatMessage } from "../../common/translate/Translate";
import { IRootState } from "../../store";
import { Datasets } from "./Datasets";
import { Groups } from "./Groups";
import { UserAdmin } from "./UserAdmin";
import { RoleAdmin } from "./RoleAdmin";
import { OrgUnit } from "./Orgunit";
import { Audits } from "./Audits";
import { getFullListOfGroups } from "../../store/groups/action";
import { getFullListOfRoles } from "../../store/role/action";
import { getFullOrganizationUnitsList } from "../../store/orgunit/action";
import { Scheduler } from "./Scheduler";
import { getFullListOfUsers } from "../../store/user/action";
import { RiskConfiguration } from "./RiskConfiguration";
import { MatchingRuleConfiguration } from "./MatchingConfiguration";
import { WorkflowStatusAdmin } from "./WorkflowStatus";
import { isUserHasPermission } from "../../common/helper/PermissionHelper";
import { User } from "../../model/user/User";
import { Permissions } from "../../common/constant/Permissions";

interface IntProps {
    currentUser?: User
}

interface IntState {
    showUser: boolean,
    showGroup: boolean,
    showRole: boolean,
    showDataset: boolean,
    showOrganizationUnit: boolean,
    showAudits: boolean,
    showScheduler: boolean,
    showRiskConfiguration: boolean,
    showMatchingConfiguration: boolean,
    showWorkflowStatusConfiguration: boolean
}

class AdministrationClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = {
            showUser: false,
            showGroup: false,
            showRole: false,
            showDataset: false,
            showOrganizationUnit: false,
            showAudits: false,
            showScheduler: false,
            showRiskConfiguration: false,
            showMatchingConfiguration: false,
            showWorkflowStatusConfiguration: false
        }

        // Administration data init
        getFullListOfGroups();
        getFullListOfRoles();
        getFullOrganizationUnitsList();
        getFullListOfUsers();
    }

    public render() {

        return (
            <React.Fragment>
                <div className="main-search" style={{ width: '18rem' }}>
                    {isUserHasPermission(this.props.currentUser, Permissions.USER_MANAGEMENT) &&
                        <button className="m-button m-button--inline" type="button" style={{ textAlign: 'left', paddingLeft: '20px' }} onClick={this.showUser}>
                            {formatMessage('admin_main_user')}
                        </button>
                    }

                    {isUserHasPermission(this.props.currentUser, Permissions.GROUP_MANAGEMENT) &&
                        <button className="m-button m-button--inline" type="button" style={{ textAlign: 'left', paddingLeft: '20px' }} onClick={this.showGroup}>
                            {formatMessage('admin_main_group')}
                        </button>
                    }

                    {isUserHasPermission(this.props.currentUser, Permissions.ROLE_MANAGEMENT) &&
                        <button className="m-button m-button--inline" type="button" style={{ textAlign: 'left', paddingLeft: '20px' }} onClick={this.showRole}>
                            {formatMessage('admin_main_role')}
                        </button>
                    }

                    {isUserHasPermission(this.props.currentUser, Permissions.DATASET_MANAGEMENT) &&
                        <button className="m-button m-button--inline" type="button" style={{ textAlign: 'left', paddingLeft: '20px' }} onClick={this.showDataset}>
                            {formatMessage('admin_main_dataset')}
                        </button>
                    }

                    {isUserHasPermission(this.props.currentUser, Permissions.ORGANIZATIONUNIT_MANAGEMENT) &&
                        <button className="m-button m-button--inline" type="button" style={{ textAlign: 'left', paddingLeft: '20px' }} onClick={this.showOrganizationUnit}>
                            {formatMessage('admin_main_organization_unit')}
                        </button>
                    }

                    {isUserHasPermission(this.props.currentUser, Permissions.SCHEDULER_MANAGEMENT) &&
                        <button className="m-button m-button--inline" type="button" style={{ textAlign: 'left', paddingLeft: '20px' }} onClick={this.showScheduler}>
                            {formatMessage('admin_main_scheduler')}
                        </button>
                    }

                    {(isUserHasPermission(this.props.currentUser, Permissions.AUDIT_MANAGEMENT) || isUserHasPermission(this.props.currentUser, Permissions.SCREENA_AUDITS)) &&
                        <button className="m-button m-button--inline" type="button" style={{ textAlign: 'left', paddingLeft: '20px' }} onClick={this.showAudits}>
                            {formatMessage('admin_main_audits')}
                        </button>
                    }

                    {isUserHasPermission(this.props.currentUser, Permissions.RISK_MANAGEMENT) &&
                        <button className="m-button m-button--inline" type="button" style={{ textAlign: 'left', paddingLeft: '20px' }} onClick={this.showRiskConfiguration}>
                            {formatMessage('admin_main_risk')}
                        </button>
                    }

                    {isUserHasPermission(this.props.currentUser, Permissions.SCREENING_MANAGEMENT) &&
                        <button className="m-button m-button--inline" type="button" style={{ textAlign: 'left', paddingLeft: '20px' }} onClick={this.showMatchingConfiguration}>
                            {formatMessage('admin_main_screening')}
                        </button>
                    }

                    {isUserHasPermission(this.props.currentUser, Permissions.WORKFLOW_MANAGEMENT) &&
                        <button className="m-button m-button--inline" type="button" style={{ textAlign: 'left', paddingLeft: '20px' }} onClick={this.showWorkflowStatusConfiguration}>
                            {formatMessage('admin_main_workflowstatus')}
                        </button>
                    }
                </div>

                {!this.state.showAudits && 
                    <div className="main-grids">
                        {this.state.showDataset && <Datasets />}
                        {this.state.showUser && <UserAdmin />}
                        {this.state.showGroup && <Groups />}
                        {this.state.showRole && <RoleAdmin />}
                        {this.state.showOrganizationUnit && <OrgUnit />}
                        {this.state.showScheduler && <Scheduler />}
                        {this.state.showRiskConfiguration && <RiskConfiguration />}
                        {this.state.showMatchingConfiguration && <MatchingRuleConfiguration />}
                        {this.state.showWorkflowStatusConfiguration && <WorkflowStatusAdmin />}
                    </div>
                }

                {this.state.showAudits && 
                    <div className="audit-grids" >
                        <Audits />
                    </div>
                }
            </React.Fragment>
        );
    }

    private showUser = (): void => {
        this.setState({
            showUser: true,
            showGroup: false,
            showRole: false,
            showDataset: false,
            showOrganizationUnit: false,
            showAudits: false,
            showScheduler: false,
            showRiskConfiguration: false,
            showMatchingConfiguration: false,
            showWorkflowStatusConfiguration: false
        })
    }

    private showGroup = (): void => {
        this.setState({
            showUser: false,
            showGroup: true,
            showRole: false,
            showDataset: false,
            showOrganizationUnit: false,
            showAudits: false,
            showScheduler: false,
            showRiskConfiguration: false,
            showMatchingConfiguration: false,
            showWorkflowStatusConfiguration: false
        })
    }

    private showRole = (): void => {
        this.setState({
            showUser: false,
            showGroup: false,
            showRole: true,
            showDataset: false,
            showOrganizationUnit: false,
            showAudits: false,
            showScheduler: false,
            showRiskConfiguration: false,
            showMatchingConfiguration: false,
            showWorkflowStatusConfiguration: false
        })
    }

    private showDataset = (): void => {
        this.setState({
            showUser: false,
            showGroup: false,
            showRole: false,
            showDataset: true,
            showOrganizationUnit: false,
            showAudits: false,
            showScheduler: false,
            showRiskConfiguration: false,
            showMatchingConfiguration: false,
            showWorkflowStatusConfiguration: false
        })
    }

    private showOrganizationUnit = (): void => {
        this.setState({
            showUser: false,
            showGroup: false,
            showRole: false,
            showDataset: false,
            showOrganizationUnit: true,
            showAudits: false,
            showScheduler: false,
            showRiskConfiguration: false,
            showMatchingConfiguration: false,
            showWorkflowStatusConfiguration: false
        })
    }

    private showAudits = (): void => {
        this.setState({
            showUser: false,
            showGroup: false,
            showRole: false,
            showDataset: false,
            showOrganizationUnit: false,
            showAudits: true,
            showScheduler: false,
            showRiskConfiguration: false,
            showMatchingConfiguration: false,
            showWorkflowStatusConfiguration: false
        })
    }

    private showScheduler = (): void => {
        this.setState({
            showUser: false,
            showGroup: false,
            showRole: false,
            showDataset: false,
            showOrganizationUnit: false,
            showAudits: false,
            showScheduler: true,
            showRiskConfiguration: false,
            showMatchingConfiguration: false,
            showWorkflowStatusConfiguration: false
        })
    }

    private showRiskConfiguration = (): void => {
        this.setState({
            showUser: false,
            showGroup: false,
            showRole: false,
            showDataset: false,
            showOrganizationUnit: false,
            showAudits: false,
            showScheduler: false,
            showRiskConfiguration: true,
            showMatchingConfiguration: false,
            showWorkflowStatusConfiguration: false
        })
    }

    private showMatchingConfiguration = (): void => {
        this.setState({
            showUser: false,
            showGroup: false,
            showRole: false,
            showDataset: false,
            showOrganizationUnit: false,
            showAudits: false,
            showScheduler: false,
            showRiskConfiguration: false,
            showMatchingConfiguration: true,
            showWorkflowStatusConfiguration: false
        })
    }

    private showWorkflowStatusConfiguration = (): void => {
        this.setState({
            showUser: false,
            showGroup: false,
            showRole: false,
            showDataset: false,
            showOrganizationUnit: false,
            showAudits: false,
            showScheduler: false,
            showRiskConfiguration: false,
            showMatchingConfiguration: false,
            showWorkflowStatusConfiguration: true
        })
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
    currentUser: state.userStore.currentUser
})

export const Administration = connect(mapStateToProps, null)(AdministrationClass);