import { Constants } from "./constant"
import { FileStore } from "./type"


const init: FileStore = {
   listOfFiles:[]
} 
    
export const fileReducer = (state: FileStore = init, action: any): FileStore => {    
    switch (action.type) {      
        case Constants.LISTOFFILES: 
            console.log(action.payload.files)         
            return {
				...state,
				listOfFiles: action.payload.files
            } 
    
        default:
            return {
				...state,
			}
    }
}