import React from "react";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { formatMessage } from "../../common/translate/Translate";

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,
    saveSavedSearch?: (e: any) => void,
    deleteSavedSearch?: (e: any) => void,
    renameSavedSearch?: (e: any) => void
}

interface IntState {
    
}

export class UpdatedSearchContextMenu extends React.Component<IntProps, IntState> {

     public render() {    
        return (
                <React.Fragment>   
                        <div className= {this.props.visible===true?"c-popover-right-click c-lc-popover.is-visible js-lc-context" : "c-popover c-lc-popover js-lc-context"}
                                        style={{position: 'absolute' , left:this.props.x + 'px', top:this.props.y+'px'}}>
                            <div className="c-popover__body">
                                <ul className="d-list d-list--borders">
                                    <li className="d-list__item">
                                        <button className="m-button m-button--inline" type="button" onClick={this.props.saveSavedSearch}>
                                             {formatMessage('savedsearch_context_save')}
                                        </button>                                            
                                        <button className="m-button m-button--inline" type="button" onClick={this.props.deleteSavedSearch}>
                                              {formatMessage('savedsearch_context_delete')}
                                        </button> 
                                        <button className="m-button m-button--inline" type="button" onClick={this.props.renameSavedSearch}>
                                              {formatMessage('savedsearch_context_rename')}
                                        </button>                                       
                                    </li>
                                </ul>
                            </div>
                        </div>
                </React.Fragment>
        );
    }
}