import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { Role } from "../../model/user/Role";
import { Request } from "../../model/common/Request";
import { createRole, updateRole } from "../../store/role/action";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import Select, { ActionMeta, OptionsType } from 'react-select';
import { Permissions } from "../../common/constant/Permissions";

interface IntProps {
    visible?: boolean,
    x?: number,
    y?: number,
    role?: Role,
    closePopup?: () => void,
    create?: boolean,
    paginationRequest?: PaginationRequest
}

interface IntState {
    roleName: string,
    roleDescription: string,
    permissions: string[],
    errors: {
        roleName: string,
        permissions: string
    }
}

class RoleDetailsPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)

        this.state = {
            roleName: '',
            roleDescription: '',
            permissions: [],
            errors: {
                roleName: '',
                permissions: ''
            }
        }
    }

    public componentWillReceiveProps(nextProps: IntProps) {
        if (nextProps.create) {
            this.setState({
                roleName: '',
                roleDescription: '',
                permissions: []
            })
        } else if (nextProps.role) {
            this.setState({
                roleName: nextProps.role.name,
                roleDescription: nextProps.role.description,
                permissions: nextProps.role.permissions
            })
        }

    }

    public render() {

        let selectedPermissions: { value: string; label: string; }[] = [];
        if (this.props.role && this.state.permissions) {
            for (var perm of Permissions.optionPermissions) {
                for (var permrole of this.state.permissions) {
                    if (permrole === perm.value) {
                        selectedPermissions.push(perm);
                    }
                }
            }
        }

        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "d-modal" : "c-popover c-lc-popover js-lc-context"} style={{ width: "50rem", height: "25rem" }}>
                    <div className="d-modal__view js-modal-view">
                        <div className="d-modal__head">
                            <legend className="d-modal__title">
                                {formatMessage('alert_details_information_title')}
                            </legend>
                            <div>
                                <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                    <svg className="o-icon o-icon--close o-icon--prepended">
                                        <title>{IconsEnum.CLOSE.title}</title>
                                        <use href={IconsEnum.CLOSE.url} />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="d-modal__view js-modal-view" style={{ margin: "1rem", height : '16rem' }}>
                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_name')}*
                                </label>
                                <input
                                    className={this.state.errors && this.state.errors["roleName"] ? "o-input error" : "o-input"}
                                    name="rolename"
                                    placeholder={formatMessage('common_name')}
                                    value={this.state.roleName}
                                    onChange={this.changeRoleName}
                                    style={{ marginTop: '2px' }}
                                />
                            </div>
                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_description')}
                                </label>
                                <input
                                    className="o-input"
                                    name="rolename"
                                    placeholder={formatMessage('common_description')}
                                    value={this.state.roleDescription}
                                    onChange={this.changeRoleDescription}
                                    style={{ marginTop: '2px' }}
                                />
                            </div>
                            <label className="o-label" style={{ marginBottom: '5px' }} >
                                {formatMessage('common_permissions')}*
                            </label>
                            <Select options={Permissions.optionPermissions}
                                value={selectedPermissions}
                                name="permissions"
                                classNamePrefix={this.state.errors && this.state.errors["permissions"] ? "basic-multi-select_error" : "basic-multi-select"}
                                isMulti
                                onChange={this.changePermissionSelect}
                            />
                        </div>

                        <div style={{ display: 'flex'}}>
                            <button className="m-button m-button--small-margin-bottom" onClick={this.props.closePopup} style={{ margin: '5px' }}>
                                {formatMessage('button_cancel')}
                            </button>

                            <button className="m-button m-button--small-margin-bottom" onClick={this.saveRole} style={{ margin: '5px' }}>
                                {formatMessage('button_save')}
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private changePermissionSelect = (values: OptionsType<{ value: string; label: string; }>, actionMeta: ActionMeta<{ value: string; label: string; }>): void => {

        let permissions: string[] = []

        if (values) {
            for (var value of values) {
                permissions.push(value.value);
            }
        }
        
        this.setState({                        
            ...this.state,  
            permissions : permissions
        });
        
    }

    private changeRoleName = (event: { target: { value: any; }; }): void => {
        this.setState({
            ...this.state,
            roleName: event.target.value
        });
    }

    private changeRoleDescription = (event: { target: { value: any; }; }): void => {
        this.setState({
            ...this.state,
            roleDescription: event.target.value
        });
    }

    private saveRole = (): void => {
        let errors = {
            roleName: '',
            permissions: ''
        };

        if (!this.state.roleName || this.state.roleName === '') {
            errors.roleName = 'No role name';
            this.setState({
                ...this.state,
                errors: errors
            })

            return;
        }

        let request: Request = new Request();
        if (this.props.create) {
            let role: Role = new Role();
            role.name = this.state.roleName;
            role.description = this.state.roleDescription;
            role.permissions = this.state.permissions;
            request.role = role;
            createRole(request, this.props.paginationRequest);
        } else if (this.props.role) {
            let role: Role = this.props.role;
            role.name = this.state.roleName;
            role.description = this.state.roleDescription;
            role.permissions = this.state.permissions;
            request.role = role;
            updateRole(request, this.props.paginationRequest);
        }

        if (this.props.closePopup) {
            this.props.closePopup();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
})

export const RoleDetailsPopup = connect(mapStateToProps, null)(RoleDetailsPopupClass);