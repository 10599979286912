import { Person } from "../../model/persons/Person"
import { PersonScoreCard } from "../../model/persons/PersonScoreCard"
import { Constants } from "./constant"
import { PersonStore } from "./type"


const init: PersonStore = {
    currentPerson:new Person(),
    currentPersonScoreCard:new PersonScoreCard(),
    listOfpersons:[],
    amountOfPersons:0,
    listOfRelatedPersons:[]
} 
    
export const personResponseReducer = (state: PersonStore = init, action: any): PersonStore => {    
    switch (action.type) {
        case Constants.CURRENTPERSON :
            return {
				...state,
				currentPerson: action.payload.person
            } 

        case Constants.PAGEDLISTS:            
            return {
				...state,
				listOfpersons: action.payload.persons
            } 
                        
        case Constants.TOTALAMOUNTOFPERSONS:     
            return {
				...state,
				amountOfPersons: action.payload.amountOfPersons
            } 
        
        case Constants.CURRENTPERSONSCORECARD:     
            return {
                ...state,
                currentPersonScoreCard: action.payload.personScoreCard
            } 

        case Constants.CURRENTPERSONRELATED:     
            return {
                ...state,
                listOfRelatedPersons: action.payload.persons
            }
            

        default:
            return {
				...state,
			}
    }
}