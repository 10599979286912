import React from "react";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { Name } from "../../model/persons/Name";
import { capitalizeWords } from "../../common/helper/StringHelper";

interface IntProps {
    field?: string,
    value?: Name[],
    amountOnTarget?: number
}

interface IntState {
    open: boolean
}

export class MultipleAttributeDisplayName extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = ({
            open: false
        });
    }

    public render() {
        const addItems = [];

        if (!(this.props.value && this.props.value.length > 0)) {
            addItems.push(
                <div className="attribute body">
                    <span className="attribute label withvalue">
                        {this.props.field}
                    </span>
                    <span className="attribute value" />
                </div>
            );
        }

        if (this.props.value && this.props.value.length > 0) {
            if (this.state.open || this.props.value.length === 1) {
                return (
                    <React.Fragment>
                        {this.props.value.map((elem, j) => {
                            let strValue: string = this.getFullNameForDisplay(elem);
                            return (
                                <div id={"div" + this.props.field + j} className="attribute body">
                                    <span id={"spanlbl" + this.props.field + j} className="attribute label withvalue">
                                        {j === 0 ? this.props.field : ''}
                                    </span>
                                    <span id={"spanvlue" + this.props.field + j} className="attribute value">
                                        {strValue}
                                    </span>
                                    {j === 0 && this.props.value && this.props.value.length > 1 &&
                                        <span id={"bdg" + this.props.field + j} className="badge" onClick={this.openAttribute}>
                                            {'-'}
                                        </span>
                                    }
                                </div>
                            );

                        })
                        }

                        {addItems}

                    </React.Fragment>

                );
            } else {
                let strValue: string = this.getFullNameForDisplay(this.props.value[0]);
                return (
                    <div className="attribute body">
                        <span className="attribute label withvalue">
                            {this.props.field}
                        </span>
                        <span className="attribute value">
                            {strValue}
                        </span>
                        <span className="badge" onClick={this.openAttribute}>
                            {'+' + String(this.props.value.length - 1)}
                        </span>
                    </div>
                );
            }
        } else {
            return (
                <React.Fragment>
                    {addItems}
                </React.Fragment>
            );
        }

    }

    private openAttribute = () => {
        this.setState({
            open: !this.state.open
        });
    }

    private getFullNameForDisplay(name: Name): string {
        if (name.fullName && name.fullName.length > 0) {
            return name.fullName;
        }

        let fullNameConcat: string = '';

        if (name.surname) {
            fullNameConcat += name.surname;
            fullNameConcat += ' ';
        }

        if (name.fatherName) {
            fullNameConcat += name.fatherName;
            fullNameConcat += ' ';
        }

        if (name.givenName) {
            fullNameConcat += name.givenName;
        }

        return capitalizeWords(fullNameConcat.trim());
    }

}