import React from "react";

import '../../common/css/attribute.css'
import { formatMessage } from "../../common/translate/Translate";

interface IntProps {
    field?:string,
    value?:string,
    sex?:boolean
}

interface IntState {

}

export class AttributeDisplay extends React.Component<IntProps, IntState> {

    public render() {
        return (
            <div className="attribute body">
                <span className="attribute label withvalue">{this.props.field}</span>

                {this.props.sex && this.props.value 
                            && <span className="attribute value">{formatMessage('sex_' + this.props.value.toLowerCase())}</span>
                }
        
                {!this.props.sex &&
                    <span className="attribute value">{this.props.value}</span>
                }
            </div>
        );
    }

}