import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { formatMessage } from "../../common/translate/Translate";
import { IRootState } from "../../store";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { HeaderConstant } from "../../common/constant/HeaderConstant";
import { Person } from "../../model/persons/Person";
import { getSinglePersons } from "../../store/customer/action";
import { CustomerCommentPopup } from "./CustomerCommentPopup";
import { CustomerDetails } from "./CustomerDetails";
import { CustomerAlerts } from "./CustomerAlerts";

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,
    customerId?:number,
    customer?:Person,
    closePopup?:() => void
}

interface IntState {
    tabSelected:string,
}

class CustomerClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {
            tabSelected : HeaderConstant.GENERAL,
        }
        
    }

    public componentWillReceiveProps(nextProps: IntProps) {
        if (this.props.visible===false && nextProps.visible===true) {
            if (nextProps.customerId && nextProps.customerId!==-1) {
                getSinglePersons(String(nextProps.customerId));    
            }            
        }  
    }

    public render() {    
        let header:Array<{id: string, name: string, visibleTab: string}> = this.getHeader();

        return (
            <React.Fragment> 
                <div className= {this.props.visible===true?"c-popover c-lc-popover.is-visible js-lc-context" : "c-popover c-lc-popover js-lc-context"}
                                        style={{position: 'absolute' , left:this.props.x + 'px', top:this.props.y+'px'}}>
                            <div className={"d-modal"} style={{width: "100rem", height: "50rem"}}>
                                <div className="d-modal__view js-modal-view">
                                    <div className="d-modal__head">
                                        <legend className="d-modal__title">
                                            {formatMessage('alert_details_information_title')}
                                        </legend>
                                        <div>                                           
                                            <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                                <svg className="o-icon o-icon--close o-icon--prepended">
                                                    <title>{IconsEnum.CLOSE.title}</title>
                                                    <use href={IconsEnum.CLOSE.url}/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <nav className="c-tabs__nav">
                                        <ul className="c-tabs__list">
                                            {header.map((head, index) => {
                                                return (
                                                    <li className="c-tabs__title" key={index}>
                                                        <button className={`c-tabs__link js-tabs-link ${this.state.tabSelected === head.visibleTab ? 'is-active' : ''}`}
                                                                id={head.id}
                                                                name={head.id} onClick={this.changeVisibility}>
                                                            <span id={head.id}>{head.name}</span>
                                                        </button>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </nav>

                                    {this.state.tabSelected===HeaderConstant.COMMENT && this.props.customer && <CustomerCommentPopup person={this.props.customer}/>}
                                    {this.state.tabSelected===HeaderConstant.GENERAL && this.props.customer && <CustomerDetails/>}
                                    {this.state.tabSelected===HeaderConstant.ALERTS && this.props.customer && <CustomerAlerts/>}
                                </div>
                            </div>
                    </div>                   
            </React.Fragment>
        );
    }

    private changeVisibility= (event: any) => {      
        this.setState({
            ...this.state,
            tabSelected : event.target.id            
        })
    }

    private getHeader = () => {
        const header: Array<{id: string, name: string, visibleTab: string}> = [];
        
        header.push({id:HeaderConstant.GENERAL, name: formatMessage('main_tab_header_general'), visibleTab: HeaderConstant.GENERAL});
        header.push({id:HeaderConstant.COMMENT,name: formatMessage('alertPopup_tab_header_comment'), visibleTab: HeaderConstant.COMMENT});
        header.push({id:HeaderConstant.TRANSACTIONS,name: formatMessage('alertPopup_tab_header_transactions'), visibleTab: HeaderConstant.TRANSACTIONS});
        header.push({id:HeaderConstant.ALERTS,name: formatMessage('main_tab_header_alerts'), visibleTab: HeaderConstant.ALERTS});
        return header;
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    customer : state.personStore.currentPerson
})

export const Customer = connect(mapStateToProps, null)(CustomerClass);



