import { Account } from "../../model/account/Account"
import { Constants } from "./constant"
import { AccountStore } from "./type"


const init: AccountStore = {
    listOfAccounts : [],
    amountOfAccounts : -1,
    currentAccount : new Account()
} 
    
export const accountResponseReducer = (state: AccountStore = init, action: any): AccountStore => {    
    switch (action.type) {
        case Constants.TOTALAMOUNTOFACCOUNTS :
            return {
				...state,
				amountOfAccounts: action.payload.numberOfObjects
            } 

        case Constants.PAGEDLISTS:            
            return {
				...state,
				listOfAccounts: action.payload.accounts
            }        

        default:
            return {
				...state,
			}
    }
}