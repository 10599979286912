import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { Request } from "../../model/common/Request";
import { DataSets } from "../../model/dataset/DataSets";
import { createDataset, updateDataset } from "../../store/dataset/action";
import Select, { ActionMeta, OptionsType } from 'react-select';
import { Group } from "../../model/user/Group";
import { OrganizationUnit } from "../../model/user/OrganizationUnit";
import { User } from "../../model/user/User";
import { DatasetConstant } from "../../common/constant/DatasetConstant";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { DataSetsFolder } from "../../model/dataset/DataSetsFolder";

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,
    dataset?:DataSets,
    create?:boolean,
    closePopup?:() => void,
    fullGroupList?:Group[],
    fullUserList?:User[],
    fullOrganizationList?:OrganizationUnit[],
    paginationRequest?:PaginationRequest,
    currentFolderId?:number
}

interface IntState {
    datasetName:string,  
    datasetDescription:string,
    datasetType:string,
    datasetClassification:string,
    datasetSourceOfData:number,
    datasetUser:number[],
    datasetGroup:number[],
    datasetOrgUnit:number[],
    errors:{
        datasetName:string,
        datasetType:string
    }
}

class DatasetDetailsPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {
            datasetName:'',   
            datasetDescription:'',
            datasetType:'',
            datasetClassification:'',
            datasetSourceOfData:0,
            datasetUser:[],
            datasetGroup:[],
            datasetOrgUnit:[],
            errors:{
                datasetName:'',
                datasetType:''
            }       
        }
    }

    public componentWillReceiveProps(nextProps: IntProps) {
        if (nextProps.create) {
            this.setState({
                datasetName : '',
                datasetDescription : '',
                datasetType: '',
                datasetSourceOfData : 0,
                errors:{
                    datasetName:'',
                    datasetType:''
                } 
            })
        } else if (nextProps.dataset) {    
            
            let selectedGroup:number[] = [];
            if (nextProps.dataset.groups) {
                for (var group of nextProps.dataset.groups) {
                    selectedGroup.push(group.id);
                }
            }   
            
            let selectedUser:number[] = [];
            if (nextProps.dataset.users) {
                for (var user of nextProps.dataset.users) {
                    selectedUser.push(user.id);
                }
            } 

            let selectedOrgunit:number[] = [];
            if (nextProps.dataset.organizations) {
                for (var orgunit of nextProps.dataset.organizations) {
                    selectedOrgunit.push(orgunit.id);
                }
            } 

           this.setState({
                datasetName : nextProps.dataset.label,
                datasetDescription : nextProps.dataset.description,
                datasetType : nextProps.dataset.type,
                datasetSourceOfData : nextProps.dataset.sourceOfData,

                datasetGroup : selectedGroup,
                datasetUser : selectedUser,
                datasetOrgUnit : selectedOrgunit,

                errors:{
                    datasetName:'',
                    datasetType:''
                } 
          })
        }
           
    }

    public render() {    
        let optionGroup:{value: string;label: string;}[] = [];
        let selectedGroup:{value: string;label: string;}[] = [];
        if (this.props.fullGroupList) {
            let i:number = 0;
            for (var group of this.props.fullGroupList) {
                optionGroup.push(
                    {
                        value : String(group.id),
                        label : group.name
                    }
                )

                for (var usergroupid of this.state.datasetGroup) {
                    if (usergroupid===group.id) {
                        selectedGroup.push (optionGroup[i]);
                    }
                }

                i++;
            }
        }

        let optionUser:{value:string;label:string;}[] = [];
        let selectedUser:{value: string;label: string;}[] = [];
        if (this.props.fullUserList) {
            let i:number = 0;
            for (var user of this.props.fullUserList) {
                optionUser.push(
                    {
                        value : String(user.id),
                        label : user.userName
                    }
                )

                for (var datasetuserid of this.state.datasetUser) {
                    if (datasetuserid===user.id) {
                        selectedUser.push (optionUser[i]);
                    }
                }

                i++;
            }
        }

        let optionOrgUnit:{value:string;label:string;}[] = [];
        let selectedOrgunit:{value: string;label: string;}[] = [];
        if (this.props.fullOrganizationList) {
            let i:number = 0;
            for (var orgunit of this.props.fullOrganizationList) {                
                optionOrgUnit.push(
                    {
                        value : String(orgunit.id),
                        label : orgunit.name
                    }
                )

                for (var datasetorgunitid of this.state.datasetOrgUnit) {
                    if (datasetorgunitid===orgunit.id) {
                        selectedOrgunit.push (optionOrgUnit[i]);
                    }
                }

                i++;
            }
        }

        let optionDataSetType:{value: string;label: string;}[] = [];
        for (var typeDs of DatasetConstant.DATASET_TYPE) {
            if (typeDs.value === this.state.datasetType) {
                optionDataSetType.push(typeDs);
            }
        }

        let optionDataSetSource:{value: number;label: string;}[] = [];
        for (var typeDsource of DatasetConstant.DATASET_SOURCE) {
            if (typeDsource.value === this.state.datasetSourceOfData) {
                optionDataSetSource.push(typeDsource);
            }
        }

        let optionDatasetClassification:{value: string;label: string;}[] = [];
        for (var classification of DatasetConstant.DATASET_CLASSIFICATION) {
            if (classification.value === this.state.datasetClassification) {
                optionDatasetClassification.push(classification);
            }
        }
        
        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "d-modal" : "c-popover c-lc-popover js-lc-context"} style={{ width: "50rem", height: "44rem" }}>
                    <div className="d-modal__view js-modal-view">
                        <div className="d-modal__head">
                            <legend className="d-modal__title">
                                {formatMessage('alert_details_information_title')}
                            </legend>
                            <div>                                
                                <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                    <svg className="o-icon o-icon--close o-icon--prepended">
                                        <title>{IconsEnum.CLOSE.title}</title>
                                        <use href={IconsEnum.CLOSE.url} />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="d-modal__view js-modal-view" style={{ margin: "1rem" }}>
                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_name')}*
                                </label>
                                <input
                                    className={this.state.errors && this.state.errors["datasetName"]? "o-input error" : "o-input"}
                                    name="datasetName"
                                    placeholder={formatMessage('common_name')}
                                    value={this.state.datasetName}
                                    onChange={this.changeDatasetName}
                                    style={{ marginTop: '2px' }}
                                />
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_description')}
                                </label>
                                <input
                                    className="o-input"
                                    name="datasetDescription"
                                    placeholder={formatMessage('common_description')}
                                    value={this.state.datasetDescription}
                                    onChange={this.changeDatasetDescription}
                                    style={{ marginTop: '2px' }}
                                />
                            </div>

                            <div >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_type')}*
                                </label>
                                <Select name="datasetType"                                                                                                                     
                                        className={this.state.errors && this.state.errors["datasetType"]? "basic-multi-select error" : "basic-multi-select"}
                                        value={optionDataSetType}
                                        options={DatasetConstant.DATASET_TYPE}
                                        classNamePrefix="select"
                                        onChange={this.changeDatasetType}
                                />  
                            </div>

                            <div >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_classification')}
                                </label>
                                <Select name="datasetClassification"                                                                                                                     
                                        className="basic-multi-select"
                                        value={optionDatasetClassification}
                                        options={DatasetConstant.DATASET_CLASSIFICATION}
                                        classNamePrefix="select"
                                        onChange={this.changeDatasetClassification}
                                />  
                            </div>

                            <div >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('dataset_sourceofdata')}
                                </label>
                                <Select name="datasetSourceOfData"                                                                                                                     
                                        className="basic-multi-select"
                                        value={optionDataSetSource}
                                        options={DatasetConstant.DATASET_SOURCE}
                                        classNamePrefix="select"
                                        isClearable
                                        onChange={this.changeDatasetSourceOfData}
                                />                               
                            </div>

                            <label className="o-label" style={{marginBottom: '5px'}} >{formatMessage('common_groups')}</label>
                            <Select name="datasetGroups"                                                                                                                     
                                        className="basic-multi-select"
                                        value={selectedGroup}
                                        options={optionGroup}
                                        classNamePrefix="select"
                                        onChange={this.changeGroupsSelect}
                                        isMulti
                                />

                            <label className="o-label" style={{marginBottom: '5px'}} >{formatMessage('common_users')}</label>
                            <Select options={optionUser}
                                        name="datasetUsers" 
                                        value={selectedUser}                                                                             
                                        isMulti
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={this.changeUserSelect}
                                />

                            <label className="o-label" style={{marginBottom: '5px'}} >{formatMessage('common_organizationunit')}</label>
                            <Select options={optionOrgUnit}
                                        value={selectedOrgunit}
                                        name="datasetOrgunits"                                                                              
                                        isMulti
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={this.changeOrgUnitSelect}
                                />
                        </div>

                        <div style={{display : 'flex'}}>
                                <button className="m-button m-button--small-margin-bottom" onClick={this.props.closePopup} style={{margin: '5px'}}>
                                    {formatMessage('button_cancel')}
                                </button> 
                                
                                <button className="m-button m-button--small-margin-bottom" onClick={this.saveDataset} style={{margin: '5px'}}>
                                    {formatMessage('button_save')}
                                </button> 
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private changeGroupsSelect = (values: OptionsType<{value: string;label: string;}>, actionMeta: ActionMeta<{value: string;label: string;}>):void => {        
        let groups:number[]=[]

        if (values) {
            for (var value of values) {
                groups.push(Number(value.value));
            }
        }

        this.setState({                        
            ...this.state,  
            datasetGroup : groups                
        });
    }

    private changeUserSelect = (values: OptionsType<{value: string;label: string;}>, actionMeta: ActionMeta<{value: string;label: string;}>):void => {
        
        let roles:number[]=[]

        if (values) {
            for (var value of values) {
                roles.push(Number(value.value));
            }
        }

        this.setState({                        
            ...this.state,  
            datasetUser : roles                
        });
    }

    private changeOrgUnitSelect = (values: OptionsType<{value: string;label: string;}>, actionMeta: ActionMeta<{value: string;label: string;}>):void => {
        
        let orgunits:number[]=[]

        if (values) {
            for (var value of values) {
                orgunits.push(Number(value.value));
            }
        }

        this.setState({                        
            ...this.state,  
            datasetOrgUnit : orgunits                
        });
    }

    private changeDatasetName = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            datasetName: event.target.value
        });
    }

    private changeDatasetDescription = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            datasetDescription: event.target.value
        });
    }

    private changeDatasetType = (value: { value: string; label: string; } | null): void => {
        if (value?.value) {
            this.setState({                        
                ...this.state,      
                datasetType: value?.value
            });
        }
    }

    private changeDatasetClassification = (value: { value: string; label: string; } | null): void => {
        if (value?.value) {
            this.setState({                        
                ...this.state,      
                datasetClassification: value?.value
            });
        }
    }

    private changeDatasetSourceOfData = (value: { value: number; label: string; } | null): void => {

        if (value?.value) {
            this.setState({                        
                ...this.state,      
                datasetSourceOfData: value?.value
            });
        } else {
            this.setState({                        
                ...this.state,      
                datasetSourceOfData: 0
            });
        }
    }

    private saveDataset = () : void => {

        let errors = {
            datasetName  : '',
            datasetType : ''
        };

        let noError:boolean = true;

        if (!this.state.datasetName || this.state.datasetName==='') {
            errors.datasetName = 'No dataset name';
            noError = false;
        }

        if (!this.state.datasetType || this.state.datasetType==='') {
            errors.datasetType = 'No dataset type';
            noError = false;
        }

        if (!noError) {
            this.setState({
                ...this.state, 
                errors:errors       
           })
   
           return;
        }

        let request:Request = new Request();
        let dataset:DataSets = new DataSets();

        if (this.props.dataset) {
            dataset = this.props.dataset;
        }
        
        dataset.label = this.state.datasetName;
        dataset.description = this.state.datasetDescription;
        dataset.type = this.state.datasetType;
        dataset.sourceOfData = this.state.datasetSourceOfData;

        if (this.props.currentFolderId && this.props.currentFolderId!==-1) {
            let dsFolder:DataSetsFolder = new DataSetsFolder();
            dsFolder.id = this.props.currentFolderId;
            dataset.dataSetFolder = dsFolder;
        }

        // Groups
        dataset.groups = [];
        for (var groupid of this.state.datasetGroup) {
            let group:Group = new Group();
            group.id = groupid;
            dataset.groups.push(group);
        }

        dataset.organizations = [];
        for (var orgunitid of this.state.datasetOrgUnit) {
            let orgunit:OrganizationUnit = new OrganizationUnit();
            orgunit.id = orgunitid;
            dataset.organizations.push(orgunit);
        }

        dataset.users = [];
        for (var userid of this.state.datasetUser) {
            let user:User = new User();
            user.id = userid;
            dataset.users.push(user);
        }

        request.dataset = dataset;         

        if (this.props.create) {               
            createDataset(request,this.props.paginationRequest);
        } else if (this.props.dataset) {
            updateDataset(request,this.props.paginationRequest);
        }

        if (this.props.closePopup) {
            this.props.closePopup();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    fullGroupList : state.groupStore.fullListOfGroups,
    fullUserList : state.userStore.fullListOfUsers,
    fullOrganizationList : state.organizationUnitStore.fullListOfOrganizationUnits
})

export const DatasetDetailsPopup = connect(mapStateToProps, null)(DatasetDetailsPopupClass);