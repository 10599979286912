import { createIntl, createIntlCache } from "react-intl";
import { english } from "./en";

import * as React from 'react';
import { connect } from "react-redux";
import { IRootState } from "../../store";

interface IntProps {
    language: string
}

class Translate extends React.Component<IntProps> {
    public render() {
        changeLanguage(this.props.language)
        return null;
    }
} 

export const messages = {
  en: english
};

const cache = createIntlCache();

let int = createIntl({
        locale: "en",
        messages: messages["en"]
    },
    cache
);

export function changeLanguage(lang: string) {
    const newIntl = createIntl(
    {
        locale: lang,
        messages: messages["en"]
    },
        cache
    );
        int = newIntl;
}

export const formatMessage = (id: string, values?: {}) => {    
    return int.formatMessage({ id }, values);
};

const mapStateToProps = (state: IRootState) => ({
    language: state.localStore.local
});
  
export default connect(mapStateToProps, null)(Translate);
