import React from "react";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { WorkflowStatus } from "../../model/alerts/WorkflowStatus";

interface IntProps {
    visible?: boolean,
    x?: number,
    y?: number,
    showAlertDetail?: (e: any) => void,
    showCustomerDetails?: (e: any) => void,
    executeWorkflow?: (e: any) => void,
    nextSteps?: WorkflowStatus[]
}

interface IntState {

}

export class AlertsContextMenu extends React.Component<IntProps, IntState> {

    public render() {
        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "c-popover-right-click c-lc-popover.is-visible js-lc-context" : "c-popover c-lc-popover js-lc-context"}
                    style={{ position: 'absolute', left: this.props.x + 'px', top: this.props.y + 'px' }}>
                    <div className="c-popover__body">
                        <ul className="d-list d-list--borders">
                            {this.props.nextSteps && this.props.nextSteps.map((item, j) => {
                                return (
                                    <li className="d-list__item" >
                                        <button key={item.code} id={String(item.id)} className="m-button m-button--inline" type="button"
                                            style={{ textAlign: 'left', paddingLeft: '20px', width: 'fit-content' }} onClick={this.props.executeWorkflow}>
                                            {item.description}
                                        </button>
                                    </li>
                                );
                            }
                            )}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}