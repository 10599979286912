export enum Constants {
    LISTOFPERSONS = 'LISTOFPERSONS',
    LISTOFPERSONSCOUNT = 'LISTOFPERSONSCOUNT',

    LISTOFALERTS = 'LISTOFALERTS',
    LISTOFALERTSCOUNT = 'LISTOFALERTSCOUNT',

    LISTOFACCOUNTS = 'LISTOFACCOUNTS',
    LISTOFACCOUNTSCOUNT = 'LISTOFACCOUNTSCOUNT',


    // Screena
    SCREENA_SEARCH_RESULTS = 'SCREENA_SEARCH_RESULTS',
    SCREENA_DATASETS_RECORD = 'SCREENA_DATASETS_RECORD',
    SCREENA_DATASETS_RECORD_COUNT = 'SCREENA_DATASETS_RECORD_COUNT',

    CLEANSEARCHES = 'CLEANSEARCHES',
}