export function hashCode(str: string): number {
    var h: number = 0;
    for (var i = 0; i < str.length; i++) {
        h = 31 * h + str.charCodeAt(i);
    }
    return h & 0xFFFFFFFF
}

export function capitalizeWords(text:string|undefined){
    if (text) {
        return text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
    } else {
        return '';
    }
};