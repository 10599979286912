import { Constants } from "./constant"
import { DatasetStore } from "./type"

const init: DatasetStore = {
    fullListOfDatasets:[],
    customerListOfDatasets:[],
    listOfDatasets:[],
    amountOfDatasets:-1,
    fullListOfDatasetsFolder:[],
    importErrors:[],
    importing:false,
} 
    
export const datasetResponseReducer = (state: DatasetStore = init, action: any): DatasetStore => {    
    switch (action.type) {
        case Constants.PAGEDDATASETS:          
            return {
				...state,
				listOfDatasets: action.payload.datasets
            } 
            
        case Constants.FULLDATASETS:
            return {
				...state,
				fullListOfDatasets: action.payload.datasets
            }
            
        case Constants.FULLDATASETSFOLDER:
            return {
				...state,
				fullListOfDatasetsFolder: action.payload.datasetFolders
            }

        case Constants.TOTALAMOUNTOFDATASETS:     
            return {
				...state,
				amountOfDatasets: action.payload.numberOfObjects
            } 
        
        case Constants.CUSTOMERDATASETS:     
            return {
				...state,
				customerListOfDatasets: action.payload.datasets
            } 

        case Constants.IMPORTFILES:
            return {
				...state,
				importErrors: action.payload.audits,
                importing: false
            }

        case Constants.IMPORTFILESERRORCLEAN:
            return {
                ...state,
                importErrors: []
            }
            
        case Constants.IMPORTFILESIMPORTING:
            return {
                ...state,
                importing: true
            }

            
        default:
            return {
				...state,
			}
    }
}