export enum Constants {   
    PERSONPAGEDLISTS = 'PERSONPAGEDLISTS',     
    PERSONTOTALAMOUNT = 'PERSONTOTALAMOUNT',
    GETRISKCONFIGURATION = 'GETRISKCONFIGURATION',
    GETRISKGLOBALSCORECONFIGURATION = 'GETRISKGLOBALSCORECONFIGURATION',
    SAVERISKCONFIGURATION = 'SAVERISKCONFIGURATION',
    DELETERISKCONFIGURATION = 'DELETERISKCONFIGURATION',
    ALLSCENARIO = 'ALLSCENARIO',

    SAVEGLOBALCALCRISKCONFIGURATION = 'SAVEGLOBALCALCRISKCONFIGURATION',
    UPDATEGLOBALCALCRISKCONFIGURATION = 'UPDATEGLOBALCALCRISKCONFIGURATION',
}