import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Request } from "../../model/common/Request";
import { Response } from "../../model/common/Response";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { Constants } from "./constant";


export const getSinglePersons = (customerId:string) => {
    displayLoadingStatus();
    
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.personSingleDetails + customerId,  axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        getPersonScoreCard(customerId);
        return store.dispatch({
            payload: response.data,
            type: Constants.CURRENTPERSON
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getPaginatedPersons = (request:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.personsList, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        console.log(response.data);
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDLISTS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getTotalAmountOfPersons = (request:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.personsCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        console.log(response.data);
        return store.dispatch({
            payload: response.data,
            type: Constants.TOTALAMOUNTOFPERSONS
        });        
    })
    .catch((error: AxiosError<String>) => {   
        manageAxiosError(error);     
        stopLoadingStatus();
    })
}

export const addCommentToPerson = (request:Request) => {
    displayLoadingStatus();

    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.personAddComment, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        getSinglePersons(String(request.person.id)); 
        return store.dispatch({
            payload: response.data,
            type: Constants.PERSONCOMMENT
        });        
    })
    .catch((error: AxiosError<String>) => {  
        manageAxiosError(error);      
        stopLoadingStatus();
    })

}

export const getPersonScoreCard = (customerId:string) => {
    displayLoadingStatus();
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.personScoreCard + customerId,  axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        console.log(response.data);
        return store.dispatch({
            payload: response.data,
            type: Constants.CURRENTPERSONSCORECARD
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getPersonRelated = (customerId:string) => {
    displayLoadingStatus();
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.personRelated + customerId,  axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.CURRENTPERSONRELATED
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

