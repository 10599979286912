import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Request } from "../../model/common/Request";
import { Response } from "../../model/common/Response";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { Constants } from "./constant";


export const getSingledAlert = (alertId:number) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.getSingleAlert + String(alertId), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        console.log(response.data);
        return store.dispatch({
            payload: response.data,
            type: Constants.SINGLEALERT
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getPaginatedAlert = (request:PaginationRequest, action:string=Constants.PAGEDLISTS) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.alertList, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: action
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}


export const getTotalAmountOfAlert = (request:PaginationRequest, action:string=Constants.TOTALAMOUNTOFALERTS) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.alertCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: action
        });        
    })
    .catch((error: AxiosError<String>) => {     
        manageAxiosError(error);   
        stopLoadingStatus();
    })
}

export const addCommentToAlert = (request:Request) => {
    displayLoadingStatus();

    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.alertAddComment, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        getSingledAlert(request.alert.id);
        return store.dispatch({
            payload: response.data,
            type: Constants.ALERTCOMMENT
        });        
    })
    .catch((error: AxiosError<String>) => { 
        manageAxiosError(error);       
        stopLoadingStatus();
    })

}


export const executeWorkflowOnAlert = (request:Request, paginationRequest:PaginationRequest) => {
    displayLoadingStatus();

    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.alertExecuteWorkflow, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        // getSingledAlert(request.alert.id);

        if (paginationRequest) {
            getPaginatedAlert(paginationRequest);
        }

        return store.dispatch({
            payload: response.data,
            type: Constants.ALERTEXECUTEWKF
        });        
    })
    .catch((error: AxiosError<String>) => {    
        manageAxiosError(error);    
        stopLoadingStatus();
    })

}

export const getAlertExportAsCSV = (request:PaginationRequest) => {
    displayLoadingStatus();
        
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Authorization': '' + localStorage.getItem('jwt'),             
            'Content-Type': 'application/json',
        },
        responseType : "blob"
    }
    
    Axios.post(URLConstants.getExportAsCsvAlert, JSON.stringify(request), axioConf).then((response: AxiosResponse<any>) => {        
        stopLoadingStatus();        
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'alert.zip'); //any other extension
        document.body.appendChild(link);
        link.click();  
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getAlertStats = (request:PaginationRequest) => {
    displayLoadingStatus();
        
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Authorization': '' + localStorage.getItem('jwt'),             
            'Content-Type': 'application/json'
        }
    }

    Axios.post(URLConstants.getAlertStatsBetweenTwoDate, JSON.stringify(request), axioConf).then((response: AxiosResponse<any>) => {        
        stopLoadingStatus();                
        return store.dispatch({
            payload: response.data,
            type: Constants.ALERTSTATS
        });   
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}