import { RiskGlobalScoreCalculation } from "../../model/risk/RiskGlobalScoreCalculation"
import { Constants } from "./constant"
import { RiskStore } from "./type"


const init: RiskStore = {
   listOfRiskedPerson: [],
   amountOfPersons : -1,
   riskMapping:[],
   riskGlobalScoreCalculation:new RiskGlobalScoreCalculation(),
   allScenario:[]
} 
    
export const riskResponseReducer = (state: RiskStore = init, action: any): RiskStore => {    
    switch (action.type) {
        case Constants.PERSONPAGEDLISTS:            
            return {
				...state,
				listOfRiskedPerson: action.payload.persons
            } 
            
        case Constants.PERSONTOTALAMOUNT:     
            return {
				...state,
				amountOfPersons: action.payload.numberOfObjects
            } 

        case Constants.GETRISKCONFIGURATION:
            return {
				...state,
				riskMapping: action.payload.riskMapping
            } 
        
        case Constants.GETRISKGLOBALSCORECONFIGURATION:
            return {
                ...state,
                riskGlobalScoreCalculation: action.payload.riskGlobalScoreCalculation
            }
     
        case Constants.ALLSCENARIO:
            return {
                ...state,
                allScenario: action.payload.allScenaries
            }

            
        default:
            return {
				...state,
			}
    }
}