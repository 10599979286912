import { Group } from "../user/Group";
import { OrganizationUnit } from "../user/OrganizationUnit";
import { User } from "../user/User";

export class DataSetsFolder {

    public id!:number;
	public active!:boolean;
	public parentId!:number;
	public label!:string;
	public description!:string;
	
	public organizations!:OrganizationUnit[];
	public groups!:Group[];
	public users!:User[];

}