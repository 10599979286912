import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";
import { formatMessage } from "../../common/translate/Translate";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Request } from "../../model/common/Request";
import { Response } from "../../model/common/Response";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { showNotification } from "../notification/action";
import { Constants } from "./constant";



export const getFullListOfRoles = () => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.fullRoleList, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.FULLLISTOFROLES
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getPaginatedRoles = (request:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.roleList, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.LISTOFROLES
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getTotalAmountOfRoles = (request:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.roleCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.COUNTOFROLES
        });        
    })
    .catch((error: AxiosError<String>) => {     
        manageAxiosError(error);   
        stopLoadingStatus();
    })
}

export const createRole = (request:Request, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.roleCreate, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();   
        showNotification('', formatMessage('role_action_created'), null, null) ;    
        if (paginationRequest) {
            getPaginatedRoles(paginationRequest);
            getTotalAmountOfRoles(paginationRequest);
        }
        return store.dispatch({
            payload: response.data,
            type: Constants.CREATEROLES
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const updateRole = (request:Request, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.roleUpdate, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();   
        showNotification('', formatMessage('role_action_updated'), null, null) ;    
        if (paginationRequest) {
            getPaginatedRoles(paginationRequest);
            getTotalAmountOfRoles(paginationRequest);
        }
        return store.dispatch({
            payload: response.data,
            type: Constants.CREATEROLES
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const deleteRole = (roleId:string, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.delete(URLConstants.roleDelete + roleId, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        showNotification('', formatMessage('role_action_deleted'), null, null) ;   

        if (paginationRequest) {
            getPaginatedRoles(paginationRequest);
            getTotalAmountOfRoles(paginationRequest);
        }

        return store.dispatch({
            payload: response.data,
            type: Constants.DELETEROLE
        });        
    })
    .catch((error: AxiosError<String>) => {        
        stopLoadingStatus();
        manageAxiosError(error);
    })
}