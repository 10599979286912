import store from "..";
import { Constants } from "./constant";

export const displayLoadingStatus = () => {
    
    store.dispatch({        
        type: Constants.LOADING
        });        
}


export const stopLoadingStatus = () => {
    
    store.dispatch({
        type: Constants.NOT_LOADING
        });        
}