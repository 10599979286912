import { Constants } from "./constant"
import { MatchingRuleStore } from "./type"

const init: MatchingRuleStore = {    
    listOfMatchingRules:[],    
} 
    
export const matchingRuleReducer = (state: MatchingRuleStore = init, action: any): MatchingRuleStore => {    
    switch (action.type) {
        case Constants.MATCHINGRULELISTS:          
            return {
				...state,
				listOfMatchingRules: action.payload.matchingRules
            } 
            
        default:
            return {
				...state,
			}
    }
}