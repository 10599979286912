import { Constants } from "./constant"
import { AuditStore } from "./type"


const init: AuditStore = {
    listOfAudits : [],
    amountOfAudit: 0
} 
    
export const auditResponseReducer = (state: AuditStore = init, action: any): AuditStore => {    
    switch (action.type) {
        case Constants.PAGEDLISTS:            
            return {
				...state,
				listOfAudits: action.payload.audits
            } 

        case Constants.PAGEDLISTSRULEAUDIT:
            return {
				...state,
				listOfAudits: action.payload.audits,
                amountOfAudit: -1
            }

        case Constants.TOTALAMOUNTOFAUDITS:     
            return {
				...state,
				amountOfAudit: action.payload.numberOfObjects
            }         
            
        default:
            return {
				...state,
			}
    }
}