import { Constants } from "./constant"
import { JobStore } from "./type"

const init: JobStore = {    
    listOfJobs:[],    
    amountOfJobs:-1
} 
    
export const jobResponseReducer = (state: JobStore = init, action: any): JobStore => {    
    switch (action.type) {
        case Constants.PAGEDLISTS:          
            return {
				...state,
				listOfJobs: action.payload.jobs
            } 
            
        case Constants.TOTALAMOUNTOFJOBS:     
            return {
				...state,
				amountOfJobs: action.payload.numberOfObjects
            } 

        default:
            return {
				...state,
			}
    }
}