import { Constants } from "./constant"
import { LoadingDisplayStore } from "./type"

const init: LoadingDisplayStore = {
    isLoading:false
} 
    
export const isLoadingReducer = (state: LoadingDisplayStore = init, action: any): LoadingDisplayStore => { 
    switch (action.type) {
        case Constants.LOADING:
            return {
				...state,
				isLoading: true
            }
        
        case Constants.NOT_LOADING:
            return {
                ...state,
                isLoading: false
            }

        default:
            return {
				...state,
				isLoading: false
			}
    }
}