export class OperationConstant {

    public static NUMBER_OPERATION_TYPE:{value: string;label: string;}[] = [
        {   
            value: 'MAX',
            label: 'Maximum'
        },
        {   
            value: 'MIN',
            label: 'Minimum'
        },
        {   
            value: 'ACC',
            label: 'Accumulate'
        }
    ];

    public static DATE_OPERATION_TYPE:{value: string;label: string;}[] = [
        {   
            value: 'PAST',
            label: 'Past'
        },
        {   
            value: 'FUTUR',
            label: 'Futur'
        },
        {   
            value: 'SAMEDAY',
            label: 'Same day'
        }
    ];

    public static STRING_OPERATION_TYPE:{value: string;label: string;}[] = [
        {   
            value: 'EQUAL',
            label: 'Equal'
        },
        {   
            value: 'STARTWITH',
            label: 'Start With'
        },
        {   
            value: 'ENDWITH',
            label: 'End With'
        }
    ];

    public static LISTOFFIELDS_TYPE:{value: string;label: string;type:string}[] = [
        {   
            value: 'nationality',
            label: 'Nationality',
            type: 'String'
        },
        {   
            value: 'placeOfRegistry',
            label: 'Place of registry',
            type: 'String'
        },
        {   
            value: 'flags',
            label: 'Flags',
            type: 'String'
        },
        {   
            value: 'birthplace',
            label: 'Birth Place',
            type: 'Date'
        },
        {   
            value: 'location.country',
            label: 'Location country',
            type: 'String'
        },
        {   
            value: 'activity',
            label: 'Activity',
            type: 'String'
        },
        {   
            value: 'profession',
            label: 'Profession',
            type: 'String'
        },
        {   
            value: 'identityDocuments.country',
            label: 'ID Doc. Country',
            type: 'String'
        },
        {   
            value: 'identityDocuments.expiryDate',
            label: 'ID Doc. Expiry Date',
            type: 'Date'
        },
        {   
            value: 'identityDocuments.type',
            label: 'ID Doc. Type',
            type: 'String'
        },
        {   
            value: 'identityDocuments.number',
            label: 'ID Doc. Number',
            type: 'String'
        }
    ];

}