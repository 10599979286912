export class DatasetConstant {

    public static WATCHLIST:string='Watchlist';
    public static SOURCE:string='Source';

    public static SANCTIONS:string='SANCTIONS';
    public static PEP:string='PEP';
    public static WANTED:string='WANTED';    

    public static PERSON:number=1;
    public static ACCOUNT:number=2;
    public static TRANSACTION:number=3;
    public static ALERTS:number=4;

    public static DATASET_TYPE:{value: string;label: string;}[] = [       
        {   
            value:DatasetConstant.SOURCE,
            label:'Source'
        }
    ];

    public static DATASET_CLASSIFICATION:{value: string;label: string;}[] = [
        {   
            value:DatasetConstant.SANCTIONS,
            label:'Sanctions'
        },
        {   
            value:DatasetConstant.PEP,
            label:'Peps'
        },
        {   
            value:DatasetConstant.WANTED,
            label:'Wanted'
        }
    ];


    public static DATASET_SOURCE:{value: number;label: string;}[] = [
        {   
            value:DatasetConstant.PERSON,
            label:'Persons'
        },
        {   
            value:DatasetConstant.ACCOUNT,
            label:'Accounts'
        },
        {   
            value:DatasetConstant.TRANSACTION,
            label:'Transactions'
        },
        {   
            value:DatasetConstant.ALERTS,
            label:'Alerts'
        }
    ];
    
}