import React from "react";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { Alert } from "../../model/alerts/Alert";
import { RowCardWkfAudit } from "../../components/workflow/RowCardWkfAudit";
import { formatMessage } from "../../common/translate/Translate";

interface IntProps {
    alert?:Alert
}

interface IntState {
    
}

export class AlertWorkflowAuditDisplay extends React.Component<IntProps, IntState> {

     public render() {    
        return (
                <React.Fragment>                                          
                    <div style={{overflowY:'auto', overflowX:'hidden', height:'38rem', paddingTop:'12px'}}>
                            {this.props.alert && this.props.alert.workflowAudits && this.props.alert.workflowAudits.length>0 && this.props.alert.workflowAudits.map((comment, j) => { 
                                    return (
                                        <RowCardWkfAudit key={j} wkfAudit={comment}/>
                                    );
                                }
                            )}

                            {(!this.props.alert || !this.props.alert.workflowAudits || this.props.alert.workflowAudits.length===0) &&                               
                                    <label className="o-label">{formatMessage('workflow_audit_nodata')}</label>                               
                            }
                     </div>
                     <div style={{height:'2rem'}}/>
                </React.Fragment>
        );
    }    
}