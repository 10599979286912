import { Constants } from "./constant"
import { GroupStore } from "./type"


const init: GroupStore = {
    fullListOfGroups:[],
    listOfGroups:[],
    amountOfGroups:-1
} 
    
export const groupReducer = (state: GroupStore = init, action: any): GroupStore => {    
    switch (action.type) {      
        case Constants.LISTOFGROUPS:          
            return {
				...state,
				listOfGroups: action.payload.groups
            } 

        case Constants.COUNTOFGROUPS:            
            return {
				...state,
				amountOfGroups: action.payload.numberOfObjects
            }  

        case Constants.FULLLISTOFGROUPS:
            return {
				...state,
				fullListOfGroups: action.payload.groups
            }
            
        default:
            return {
				...state,
			}
    }
}