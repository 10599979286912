import { User } from "../../model/user/User";

export function isUserHasPermission(user:User|undefined, permission:string) : boolean {
    if (!user || !permission) {
        return false;
    }

    if (!user.roles) {
        return false;
    }

    for (let i=0; i< user.roles.length;i++) {
        if (user.roles[i].permissions) {
            for (let j=0; j< user.roles[i].permissions.length;j++) {
                if (user.roles[i].permissions[j]===permission) {
                    return true;
                }
            }
        }
    }

    return false;
}