import { Constants } from "./constant"
import { OrganizationUnitStore } from "./type"


const init: OrganizationUnitStore = {
    listOfOrganizationUnits:[],
    amountOfOrganizationUnits:-1,
    fullListOfOrganizationUnits:[]
} 
    

export const organizationUnitReducer = (state: OrganizationUnitStore = init, action: any): OrganizationUnitStore => {    
    switch (action.type) {      
        case Constants.LISTOFORGANIZATIONUNITS:          
            return {
				...state,
				listOfOrganizationUnits: action.payload.organizationUnits
            } 

        case Constants.COUNTOFORGANIZATIONUNITS:            
            return {
				...state,
				amountOfOrganizationUnits: action.payload.numberOfObjects
            }  

        case Constants.FULLLISTOFORGANIZATIONUNITS:
            return {
                ...state,
                fullListOfOrganizationUnits: action.payload.organizationUnits
            }

        default:
            return {
				...state,
			}
    }
}