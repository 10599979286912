export enum Constants {
    SINGLEALERT='SINGLEALERT',
    PAGEDLISTS = 'PAGEDLISTS',     
    TOTALAMOUNTOFALERTS = 'TOTALAMOUNTOFALERTS',
    ALERTCOMMENT = 'ALERTCOMMENT',
    ALERTEXECUTEWKF = 'ALERTEXECUTEWKF',
    ALERTSTATS = 'ALERTSTATS',

    PERSONPAGEDLISTS = 'PERSONPAGEDLISTS',     
    PERSONTOTALAMOUNTOFALERTS = 'PERSONTOTALAMOUNTOFALERTS',
}