import { Constants } from "./constant"
import { CountryStore } from "./type"

const init: CountryStore = {
    fullListOfCountries:[]
} 
    
export const countryResponseReducer = (state: CountryStore = init, action: any): CountryStore => {    
    switch (action.type) {
        case Constants.FULLIST :
            return {
				...state,
				fullListOfCountries: action.payload.listOfCountries
            } 

        default:
            return {
				...state,
			}
    }
}