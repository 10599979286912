import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { Request } from "../../model/common/Request";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { WorkflowStatus } from "../../model/alerts/WorkflowStatus";
import { createWorkflowStatus, updateWorkflowStatus } from "../../store/workflow/action";
import Select, { ActionMeta, OptionsType } from 'react-select';

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,
    wkfStatus?:WorkflowStatus,
    closePopup?:() => void,
    create?:boolean,
    paginationRequest?:PaginationRequest,
    listOfWorkflowStatus?:WorkflowStatus[]
}

interface IntState {
    code:string,  
    description:string,    
    closeStatus:boolean,
    assignToUser:boolean,
    assignToGroup:boolean,
    keepCurrentStatus:boolean,
    actions:string[],
    nextSteps:WorkflowStatus[],
    errors:{
        code:string,
        description:string
    }
}

class WorkflowStatusDetailPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {
            code:'',  
            description:'',
            closeStatus:false,
            assignToUser:false,
            assignToGroup:false,
            keepCurrentStatus:false,
            actions:[],
            nextSteps:[],
            errors:{
                code:'',
                description:''
            }
        }
    }

    public componentWillReceiveProps(nextProps: IntProps) {
        if (nextProps.create) {
            this.setState({
                code : '',
                description : '',
                closeStatus:false,
                assignToUser:false,
                assignToGroup:false,
                keepCurrentStatus:false,
                actions:[],
                nextSteps:[],
                errors:{
                    code:'',
                    description:''
                }
            })
        } else if (nextProps.wkfStatus) {             
          this.setState({
                code : nextProps.wkfStatus.code,
                description : nextProps.wkfStatus.description,
                closeStatus : nextProps.wkfStatus.closeStatus,
                assignToUser : nextProps.wkfStatus.assignToUser,
                assignToGroup : nextProps.wkfStatus.assignToGroup,
                keepCurrentStatus : nextProps.wkfStatus.keepCurrentStatus,
                actions:nextProps.wkfStatus.actions,
                nextSteps:nextProps.wkfStatus.nextSteps,
                errors:{
                    code:'',
                    description:''
                }
          })
        }
           
      }

    public render() {    

        let optionWkfStatus:{value: string;label: string;}[] = [];
        let selectedStatus:{value: string;label: string;}[] = [];
        if (this.props.listOfWorkflowStatus) {
            let i:number = 0;
            for (var wkfStatus of this.props.listOfWorkflowStatus) {
                optionWkfStatus.push(
                    {
                        value : String(wkfStatus.id),
                        label : wkfStatus.code
                    }
                )

                if (this.state.nextSteps && this.state.nextSteps.length>0) {
                    for (var selectedWkfStatus of this.state.nextSteps) {
                        if (wkfStatus.id===selectedWkfStatus.id) {
                            selectedStatus.push (optionWkfStatus[i]);
                        }
                    }
                }

                i++;
            }
        }

        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "d-modal" : "c-popover c-lc-popover js-lc-context"} style={{ width: "50rem", height: "30rem" , overflow:'visible'}}>
                    <div className="d-modal__view js-modal-view">
                        <div className="d-modal__head">
                            <legend className="d-modal__title">
                                {formatMessage('alert_details_information_title')}
                            </legend>
                            <div>                               
                                <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                    <svg className="o-icon o-icon--close o-icon--prepended">
                                        <title>{IconsEnum.CLOSE.title}</title>
                                        <use href={IconsEnum.CLOSE.url} />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="d-modal__view js-modal-view" style={{ margin: "1rem" }}>
                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '140px' }}>
                                    {formatMessage('common_name')}*
                                </label>
                                <input
                                    className={this.state.errors && this.state.errors["code"]? "o-input error" : "o-input"}
                                    name="rolename"
                                    placeholder={formatMessage('common_name')}
                                    value={this.state.code}
                                    onChange={this.changeCode}
                                    style={{ marginTop: '2px' }}
                                />                                
                            </div>
                            <div style={{ display: 'flex' }}>
                                <label className="o-label" style={{ width: '140px' }}>
                                    {formatMessage('common_description')}*
                                </label>
                                <input
                                    className={this.state.errors && this.state.errors["description"]? "o-input error" : "o-input"}
                                    name="rolename"
                                    placeholder={formatMessage('common_description')}
                                    value={this.state.description}
                                    onChange={this.changeDescription}
                                    style={{ marginTop: '2px' }}
                                />                               
                            </div>
                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '120px' }}>
                                    {formatMessage('workflow_closeSatus')}
                                </label>
                                <input                                    
                                    name="closeStatus"
                                    type="checkbox"                                    
                                    checked={this.state.closeStatus}
                                    onChange={this.changeCloseStatus}
                                    style={{ height: '1rem', marginTop : '1rem' }}
                                />                                
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '120px' }}>
                                    {formatMessage('workflow_assignToUser')}
                                </label>
                                <input                                    
                                    name="assignToUser"
                                    type="checkbox"                                    
                                    checked={this.state.assignToUser}
                                    onChange={this.changeAssignToUser}
                                    style={{ height: '1rem', marginTop : '1rem'  }}
                                />                                
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '120px' }}>
                                    {formatMessage('workflow_assignToGroup')}
                                </label>
                                <input                                    
                                    name="assignToGroup"
                                    type="checkbox"                                    
                                    checked={this.state.assignToGroup}
                                    onChange={this.changeAssignToGroup}
                                    style={{ height: '1rem', marginTop : '1rem'  }}
                                />                                
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '120px' }}>
                                    {formatMessage('workflow_keepCurrentStatus')}
                                </label>
                                <input                                    
                                    name="keepCurrentStatus"
                                    type="checkbox"                                    
                                    checked={this.state.keepCurrentStatus}
                                    onChange={this.changeKeepCurentStatus}
                                    style={{ height: '1rem', marginTop : '1rem'  }}
                                />                                
                            </div>

                            <label className="o-label" style={{marginBottom: '5px'}}>
                                {formatMessage('workflow_nextSteps')}
                            </label>
                            <Select options={optionWkfStatus}
                                        value={selectedStatus}
                                        name="nextSteps"                                                                              
                                        isMulti
                                        maxMenuHeight={120}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={this.changeNextSteps}
                            />
                        </div>

                        <div style={{display : 'flex'}}>
                                <button className="m-button m-button--small-margin-bottom" onClick={this.props.closePopup} style={{margin: '5px'}}>
                                    {formatMessage('button_cancel')}
                                </button> 
                                
                                <button className="m-button m-button--small-margin-bottom" onClick={this.saveWorkflowStatus} style={{margin: '5px'}}>
                                    {formatMessage('button_save')}
                                </button> 
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private changeNextSteps = (values: OptionsType<{value: string;label: string;}>, actionMeta: ActionMeta<{value: string;label: string;}>):void => {
        
        let wkfStatusId:WorkflowStatus[]=[]

        if (values && this.props.listOfWorkflowStatus) {
            for (var value of values) {
                for (var wkfStatus of this.props.listOfWorkflowStatus) {
                    if (Number(value.value)===wkfStatus.id) {
                        wkfStatusId.push(wkfStatus);
                    }                    
                }
            }
        }

        this.setState({                        
            ...this.state,  
            nextSteps : wkfStatusId                
        });
    }

    private changeAssignToUser = (event:any) => {
        this.setState({
            ...this.state,
            assignToUser:!this.state.assignToUser
        })
    }

    private changeAssignToGroup = (event:any) => {
        this.setState({
            ...this.state,
            assignToGroup:!this.state.assignToGroup
        })
    }

    private changeKeepCurentStatus = (event:any) => {
        this.setState({
            ...this.state,
            keepCurrentStatus:!this.state.keepCurrentStatus
        })
    }

    private changeCloseStatus = (event : any) => {
        this.setState({
            ...this.state,
            closeStatus : !this.state.closeStatus
        })
    }

    private changeCode = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            code: event.target.value
        });
    }

    private changeDescription = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            description: event.target.value
        });
    }

    private saveWorkflowStatus = () : void => {
        let noError:boolean=true;
        let error : {
            code:string,
            description:string
        } = {
            code:'',
            description:''
        }

        if (this.state.code==='') {
            error.code = 'No code'; 
            noError = false;
        }

        if (this.state.description==='') {
            error.description = 'No description'; 
            noError = false;
        }

        if (!noError) {
            this.setState({
                ...this.state,
                errors:error
            })

            return;
        }

        let request:Request = new Request();
        let wkfStatus:WorkflowStatus = new WorkflowStatus();
        if (!this.props.create && this.props.wkfStatus) {
            wkfStatus = this.props.wkfStatus;
        }

        wkfStatus.code = this.state.code;
        wkfStatus.description = this.state.description;
        wkfStatus.closeStatus = this.state.closeStatus;
        wkfStatus.assignToUser = this.state.assignToUser;
        wkfStatus.assignToGroup = this.state.assignToGroup;
        wkfStatus.nextSteps = this.state.nextSteps;
        request.workflowStatus = wkfStatus;

        if (this.props.create) {
            createWorkflowStatus(request);
        } else {
            updateWorkflowStatus(request);
        }

        if (this.props.closePopup) {
            this.props.closePopup();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    listOfWorkflowStatus: state.workflowStore.listOfWorkflowStatus
})

export const WorkflowStatusDetailPopup = connect(mapStateToProps, null)(WorkflowStatusDetailPopupClass);