// config.ts
export interface DynamicConfig {
    apiUrl: string;
    screenaUrl: string;
  }
  
export const defaultConfig: DynamicConfig = {
   apiUrl: "http://localhost/rest/v1/",
   screenaUrl: "http://localhost/rest/v2/"
};
  
class GlobalConfig {
  config: DynamicConfig = defaultConfig;
}
  
export const globalConfig = new GlobalConfig();
  
export const globalConfigUrl = "config.json";