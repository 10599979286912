import React from "react";

import '../../common/css/tooltip.css'

interface IntProps {
    tooltiptext?: string[],
    tolltipTitle?: string[],
    show?: boolean,
    closeToolTip?: () => void
}

interface IntState {

}

export class ToolTip extends React.Component<IntProps, IntState> {

    public render() {
        return (
            <div className={this.props.show ? "normalizedTooltip" : "normalizedTooltip hidden"}>
                <div className="normalized-tooltip-text-content">
                    {this.props.tolltipTitle && this.props.tolltipTitle.map((item, j) => {
                            return <p id={"p" + j} className="normalized-tooltip-text-content p">{item}</p>
                        })
                    }
                    <ul className="normalized-tooltip-text-content ul">
                        {this.props.tooltiptext && this.props.tooltiptext.map((item, j) => {
                            return (<li id={"li" + j}>{item}</li>);
                        })}
                    </ul>
                </div>
                <button className="normalized-tooltip-text-content close"
                    onClick={this.props.closeToolTip} />
            </div>
        );
    }

}