import { Constants } from "./constant"
import { RoleStore } from "./type"


const init: RoleStore = {
    fullListOfRoles:[],
    listOfRoles:[],
    amountOfRoles:-1
} 
    
export const roleReducer = (state: RoleStore = init, action: any): RoleStore => {    
    switch (action.type) {      
        case Constants.LISTOFROLES:          
            return {
				...state,
				listOfRoles: action.payload.roles
            } 

        case Constants.COUNTOFROLES:            
            return {
				...state,
				amountOfRoles: action.payload.numberOfObjects
            }  

        case Constants.FULLLISTOFROLES:
            return {
				...state,
				fullListOfRoles: action.payload.roles
            }

        default:
            return {
				...state,
			}
    }
}