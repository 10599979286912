import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";
import { formatMessage } from "../../common/translate/Translate";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Request } from "../../model/common/Request";
import { Response } from "../../model/common/Response";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { showNotification } from "../notification/action";
import { Constants } from "./constant";

export const getFullOrganizationUnitsList = () => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.fullOrganizationUnitList, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.FULLLISTOFORGANIZATIONUNITS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getPaginatedOrganizationUnits = (request:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.organizationUnitList, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.LISTOFORGANIZATIONUNITS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getTotalAmountOfOrganizationUnits = (request:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.organizationUnitCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.COUNTOFORGANIZATIONUNITS
        });        
    })
    .catch((error: AxiosError<String>) => {  
        manageAxiosError(error);      
        stopLoadingStatus();
    })
}

export const createOrganizationUnits = (request:Request, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.organizationUnitCreate, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => { 
        showNotification('', formatMessage('orgunit_action_created'), null, null) ;        
        stopLoadingStatus();
        if (paginationRequest) {
            getPaginatedOrganizationUnits(paginationRequest);
            getTotalAmountOfOrganizationUnits(paginationRequest);
        }
        return store.dispatch({
            payload: response.data,
            type: Constants.CREATEORGANIZATIONUNITS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);        
        stopLoadingStatus();
    })
}

export const updateOrganizationUnits = (request:Request, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.organizationUnitUpdate, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => { 
        showNotification('', formatMessage('orgunit_action_updted'), null, null) ;        
        stopLoadingStatus();
        if (paginationRequest) {
            getPaginatedOrganizationUnits(paginationRequest);
            getTotalAmountOfOrganizationUnits(paginationRequest);
        }
        return store.dispatch({
            payload: response.data,
            type: Constants.CREATEORGANIZATIONUNITS
        });        
    })
    .catch((error: AxiosError<String>) => { 
        manageAxiosError(error);       
        stopLoadingStatus();
    })
}

export const deleteOrganizationUnits = (orgId:string, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.delete(URLConstants.organizationUnitDelete + orgId, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        showNotification('', formatMessage('role_action_deleted'), null, null) ;   

        if (paginationRequest) {
            getPaginatedOrganizationUnits(paginationRequest);
            getTotalAmountOfOrganizationUnits(paginationRequest);
        }

        return store.dispatch({
            payload: response.data,
            type: Constants.DELETEORGANIZATIONUNITS
        });        
    })
    .catch((error: AxiosError<String>) => {        
        stopLoadingStatus();
        manageAxiosError(error);
    })
}