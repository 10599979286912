import { formatMessage } from "../translate/Translate";

export class SearchConstant {

    public static IDNUMBER : string = "idnumber";
    public static LEI : string = "lei";
    public static BIC : string = "bic";

    public static INDIVIDUAL : string = "individual";
    public static ORGANIZATION : string = "organization";
    public static VESSEL : string = "vessel";
    public static UNKNOWN : string = "unknown";

    public static dateAlgo = [
        { value: 'same_day_month_year', label: formatMessage('same_day_month_year') },
        { value: 'same_month_year', label: formatMessage('same_month_year') },
        { value: 'same_year', label: formatMessage('same_year') },
        { value: 'same_quadrennium', label: formatMessage('same_quadrennium') },
        { value: 'same_decade', label: formatMessage('same_decade') }
      ]

    public static countryAlgo = [
        { value: 'same_country', label: formatMessage('same_country') },
        { value: 'same_subregion', label: formatMessage('same_subregion') },
        { value: 'same_region', label: formatMessage('same_region') }
      ]

    public static countryFields = [
        { value: 'field_nationality', label: formatMessage('person_details_nationality') },
        { value: 'field_pob', label: formatMessage('person_details_pobs') },
        { value: 'field_por', label: formatMessage('person_details_pors') },
        { value: 'field_location', label: formatMessage('person_details_location') },
        { value: 'field_flag', label: formatMessage('person_details_flags') },        
      ]

    public static dateFields = [
        { value: 'field_dob', label: formatMessage('person_details_dob') },
        { value: 'field_dobuild', label: formatMessage('person_details_dobuild') },
        { value: 'field_dor', label: formatMessage('person_details_doregistry') }
      ]

}