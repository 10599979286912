import { Group } from "./Group";
import { OrganizationUnit } from "./OrganizationUnit";
import { Role } from "./Role";
import { UserPreferences } from "./UserPreferences";

export class User {

    public id!:number;
    public userName!: string;
    public password!: string;
    public email!:string;
    public apiKey!:string;
    public jwtToken!:string;

    public userPreferences!:UserPreferences;

    public creationDate!:string
	public createdBy!:string;
	public updatedDate!:string;
	public updatedBy!:string;

    public groups!: Group[];
    public roles!: Role[];
    public organizationUnits!: OrganizationUnit[];

    public locked!:boolean;
    public deleted!:boolean;

}