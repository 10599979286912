import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { Group } from "../../model/user/Group";
import { Request } from "../../model/common/Request";
import { createGroup, updateGroup } from "../../store/groups/action";
import { PaginationRequest } from "../../model/common/PaginationRequest";

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,
    group?:Group,
    create?:boolean,
    closePopup?:() => void,
    paginationRequest?:PaginationRequest
}

interface IntState {
    groupName:string,  
    groupDescription:string,
    groupEmail:string,
    errors:{
        groupName:string
    }
}

class GroupDetailsPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {
            groupName:'',   
            groupDescription:'',
            groupEmail:'',
            errors:{
                groupName:''
            }       
        }
    }

    public componentWillReceiveProps(nextProps: IntProps) {
        if (nextProps.create) {
            this.setState({
                groupName : '',
                groupDescription : '',
                groupEmail:''
            })
        } else if (nextProps.group) {             
          this.setState({
                groupName : nextProps.group.name,
                groupDescription : nextProps.group.description,
                groupEmail : nextProps.group.email
          })
        }
           
      }

    public render() {    

        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "d-modal" : "c-popover c-lc-popover js-lc-context"} style={{ width: "50rem", height: "18rem" }}>
                    <div className="d-modal__view js-modal-view">
                        <div className="d-modal__head">
                            <legend className="d-modal__title">
                                {formatMessage('alert_details_information_title')}
                            </legend>
                            <div>                               
                                <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                    <svg className="o-icon o-icon--close o-icon--prepended">
                                        <title>{IconsEnum.CLOSE.title}</title>
                                        <use href={IconsEnum.CLOSE.url} />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="d-modal__view js-modal-view" style={{ margin: "1rem" }}>
                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_name')}*
                                </label>
                                <input
                                    className={this.state.errors && this.state.errors["groupName"]? "o-input error" : "o-input"}
                                    name="groupName"
                                    placeholder={formatMessage('common_name')}
                                    value={this.state.groupName}
                                    onChange={this.changeGroupName}
                                    style={{ marginTop: '2px' }}
                                />                                
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_description')}
                                </label>
                                <input
                                    className="o-input"
                                    name="groupDescription"
                                    placeholder={formatMessage('common_description')}
                                    value={this.state.groupDescription}
                                    onChange={this.changeGroupDescription}
                                    style={{ marginTop: '2px' }}
                                />                                
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('common_email')}
                                </label>
                                <input
                                    className="o-input"
                                    name="groupEmail"
                                    placeholder={formatMessage('common_email')}
                                    value={this.state.groupEmail}
                                    onChange={this.changeGroupEmail}
                                    style={{ marginTop: '2px' }}
                                />                                
                            </div>
                        </div>

                        <div style={{display : 'flex'}}>
                                <button className="m-button m-button--small-margin-bottom" onClick={this.props.closePopup} style={{margin: '5px'}}>
                                    {formatMessage('button_cancel')}
                                </button> 
                                
                                <button className="m-button m-button--small-margin-bottom" onClick={this.saveGroup} style={{margin: '5px'}}>
                                    {formatMessage('button_save')}
                                </button> 
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private changeGroupName = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            groupName: event.target.value
        });
    }

    private changeGroupDescription = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            groupDescription: event.target.value
        });
    }

    private changeGroupEmail = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            groupEmail: event.target.value
        });
    }

    private saveGroup = () : void => {

        let errors = {
            groupName  : ''
        };

        if (!this.state.groupName || this.state.groupName==='') {
            errors.groupName = 'No group name';
            this.setState({
                 ...this.state, 
                 errors:errors       
            })
    
            return;
        }

        let request:Request = new Request();
        if (this.props.create) {
            let group:Group = new Group();
            group.name = this.state.groupName;
            group.description = this.state.groupDescription;
            group.email = this.state.groupEmail;
            request.group = group;
            createGroup(request, this.props.paginationRequest);
        } else if (this.props.group) {
            let group:Group = this.props.group;
            group.name = this.state.groupName;
            group.description = this.state.groupDescription;
            group.email = this.state.groupEmail;
            request.group = group;
            updateGroup(request, this.props.paginationRequest);
        }

        if (this.props.closePopup) {
            this.props.closePopup();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
})

export const GroupDetailsPopup = connect(mapStateToProps, null)(GroupDetailsPopupClass);