import { Constants } from "./constant"
import { SavedSearchStore } from "./type"


const init: SavedSearchStore = {
    listOfAlertSavedSearch: []
} 
    
export const savedSearchResponseReducer = (state: SavedSearchStore = init, action: any): SavedSearchStore => {    
    switch (action.type) {
        case Constants.SEARCHLISTS:            
            return {
				...state,
				listOfAlertSavedSearch: action.payload.savedSearches
            } 

        default:
            return {
				...state,
			}
    }
}