import { SearchPairValue } from "./SearchPairValue";

export class SavedSearch {
    public id!:number;	
	public name!:string;
	public description!:string;
	public userId!:number;
	
	public searchFields!:SearchPairValue[];

}