import React from "react";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/pagination.css'
import '../../common/css/button.css'
import { IconsEnum } from "../../common/constant/IconsEnum";
import { formatMessage } from "../../common/translate/Translate";

interface IntProps {
    maxPage: number,
    pageNumber: number,
    textDisplay?: boolean,
    onChangeNext: () => void,
    onChangePrevious: () => void,
    changeInput: (e: any) => void
}

interface IntState {
    keywordSearch: string,
}

export class Pagination extends React.Component<IntProps, IntState> {

    public render() {
        return (
            <div className="r-pagination-wrapper js-pagination-wrapper" key={this.props.pageNumber}>


                <button className={this.props.pageNumber < 1 ? "button-pagination btn-prev disabled" : "button-pagination btn-prev"}
                    aria-label="Previous"
                    disabled={(this.props.pageNumber < 1)}
                    onClick={this.props.onChangePrevious}>
                    {!this.props.textDisplay &&
                        <svg className="o-icon o-icon--caret-left">
                            <title>{IconsEnum.CARETLEFTCLOSE.title}</title>
                            <use href={IconsEnum.CARETLEFTCLOSE.url} />
                        </svg>
                    }
                    {this.props.textDisplay &&
                         <React.Fragment>{formatMessage('button_previous')}</React.Fragment>
                    }
                </button>

                {(this.props.pageNumber > 2) ? <button className="button-pagination" disabled={this.props.pageNumber === 0} onClick={() => { this.props.changeInput(0) }}>1</button> : null}
                {(this.props.pageNumber > 3) ? <span className="button-pagination">...</span> : null}
                {(this.props.pageNumber > 1) ? <button className="button-pagination" onClick={() => { this.props.changeInput(this.props.pageNumber - 2) }}>{this.props.pageNumber - 1}</button> : null}
                {(this.props.pageNumber > 0) ? <button className="button-pagination" onClick={() => { this.props.changeInput(this.props.pageNumber - 1) }}>{this.props.pageNumber}</button> : null}
                <span className="current-page">{this.props.pageNumber + 1}</span>
                {(this.props.pageNumber + 1 < this.props.maxPage) ? <button className="button-pagination" onClick={() => { this.props.changeInput(this.props.pageNumber + 1) }}>{this.props.pageNumber + 2}</button> : null}
                {(this.props.pageNumber + 2 < this.props.maxPage) ? <button className="button-pagination" onClick={() => { this.props.changeInput(this.props.pageNumber + 2) }}>{this.props.pageNumber + 3}</button> : null}
                {(this.props.pageNumber + 4 < this.props.maxPage) ? <span className="button-pagination">...</span> : null}
                {(this.props.pageNumber + 3 < this.props.maxPage) ? <button className="button-pagination" disabled={this.props.pageNumber + 1 === this.props.pageNumber} onClick={() => { this.props.changeInput(this.props.maxPage - 1) }}>{this.props.maxPage}</button> : null}

                <button className={this.props.pageNumber >= this.props.maxPage - 1 ? "button-pagination btn-next disabled" : "button-pagination btn-next"}
                    aria-label="Next"
                    onClick={this.props.onChangeNext}
                    disabled={this.props.pageNumber >= this.props.maxPage - 1}>
                    {!this.props.textDisplay &&
                        <svg className="o-icon o-icon--caret-right">
                            <title>{IconsEnum.CARETLEFTCLOSE.title}</title>
                            <use href={IconsEnum.CARETLEFTCLOSE.url} />
                        </svg>
                    }
                    {this.props.textDisplay &&
                         <React.Fragment>{formatMessage('button_next')}</React.Fragment>
                    }
                </button>

            </div>
        );
    }

}