import { RiskGlobalScoreCategory } from "../risk/RiskGlobalScoreCategory";
import { RiskMapping } from "../risk/RiskMapping";
import { MatchingAlgo } from "../screena/MatchingAlgo";
import { MatchingData } from "../screena/MatchingData";

export class RequestDataObject {

    public riskMapping!:RiskMapping[];
    public riskGlobalScoreCategory!:RiskGlobalScoreCategory;

    public sourceData!:MatchingData[];
    public targetData!:MatchingData[];

    public threshold!:number;

    public entityTypeAlgo!:MatchingAlgo;
    public identityDocumentAlgo!:MatchingAlgo;
    public bicAlgo!:MatchingAlgo;
    public leiAlgo!:MatchingAlgo;
    public digitalCurrencyAlgo!:MatchingAlgo;

    public dateOfBuildAlgo!:MatchingAlgo;
    public flagAlgo!:MatchingAlgo;

    public dateOfRegistryAlgo!:MatchingAlgo;
    public placeOfRegistryAlgo!:MatchingAlgo;

    public dateOfBirthAlgo!:MatchingAlgo;
    public addressAlgo!:MatchingAlgo;

    public placeOfBirthAlgo!:MatchingAlgo;
    public nationalityAlgo!:MatchingAlgo;

    public minimumAmountOfOptional!:number;
}