import { Alert } from "../../model/alerts/Alert"
import { Statistics } from "../../model/common/Statistics"
import { Constants } from "./constant"
import { AlertStore } from "./type"


const init: AlertStore = {
    listOfAlerts : [],
    amountOfAlerts: 0,

    personListOfAlerts:[],
    personAmountOfAlerts:0,

    statistics: new Statistics(),
    alert: new Alert()

} 
    
export const alertResponseReducer = (state: AlertStore = init, action: any): AlertStore => {    
    switch (action.type) {
        case Constants.PAGEDLISTS:            
            return {
				...state,
				listOfAlerts: action.payload.alerts
            } 
            
        case Constants.TOTALAMOUNTOFALERTS:     
            return {
				...state,
				amountOfAlerts: action.payload.amountOfAlerts
            } 

        case Constants.PERSONPAGEDLISTS:            
            return {
				...state,
				personListOfAlerts: action.payload.alerts
            } 
            
        case Constants.ALERTEXECUTEWKF:            
            return {
				...state,				
            } 
            
        case Constants.PERSONTOTALAMOUNTOFALERTS:     
            return {
				...state,
				personAmountOfAlerts: action.payload.amountOfAlerts
            } 

        case Constants.SINGLEALERT:     
            return {
				...state,
				alert: action.payload.alert
            } 

        case Constants.ALERTSTATS:     
            return {
				...state,
				statistics: action.payload.statistics
            } 
            
        default:
            return {
				...state,
			}
    }
}