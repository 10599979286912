import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";
import { Alert } from "../../model/alerts/Alert";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Person } from "../../model/persons/Person";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { Constants } from "./constant";

export const uploadFile = (formData:FormData, alert:Alert) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt'),
            withCredentials: true
        }
    }

    Axios.post(URLConstants.uploadFile, formData, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();   
        let request:PaginationRequest = new PaginationRequest();
        request.alert = alert;
        getListOfFiles(request);
        return store.dispatch({
            payload: response.data,
            type: Constants.UPLOADFILE
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const uploadFilePerson = (formData:FormData, person:Person) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt'),
            withCredentials: true
        }
    }

    Axios.post(URLConstants.uploadFilePerson, formData, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();   
        let request:PaginationRequest = new PaginationRequest();
        request.person = person;
        getListOfFiles(request);
        return store.dispatch({
            payload: response.data,
            type: Constants.UPLOADFILE
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getListOfFiles = (request:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt'),
            withCredentials: true
        }
    }

    Axios.post(URLConstants.listFileAttachedTo, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.LISTOFFILES
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const downloadFile = (documentId:string, fileName:string) => {

    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
             'Authorization': '' + localStorage.getItem('jwt'),             
        },
        responseType : "blob"
    }

    Axios.get(URLConstants.downloadFile + documentId, axioConf).then((response: AxiosResponse<any>) => {        
        stopLoadingStatus();        
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName); //any other extension
        document.body.appendChild(link);
        link.click();

        // link.remove();     
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}