export class PersonFieldsConstant {

    public static IDFIELD:string='id';

    public static DATAIDFIELD:string='dataId';
    public static SEXFIELD:string='sex';
    public static ENTITYTYPEFIELD:string='entityType';
    public static RISKSINCEFIELD:string='riskSince';
    public static RISKSCOREFIELD:string='riskScore';
    public static NAMEFIELD:string='name.fullName';

    public static CREATED_BY:string='createdBy';
    public static CREATED_ON:string='creationDtg';
    public static UPDATED_BY:string='updatedBy';
    public static UPDATED_ON:string='updateDtg';
    
}