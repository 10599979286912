import { Constants } from "./constant"
import { WorkflowStore } from "./type"

const init: WorkflowStore = {
    listOfWorkflowStatus : []
} 
    
export const workflowReducer = (state: WorkflowStore = init, action: any): WorkflowStore => {    
    switch (action.type) {
        case Constants.WORKFLOWSTATUSLIST:            
            return {
				...state,
				listOfWorkflowStatus: action.payload.listOfWorkflowStatus
            } 

        default:
            return {
				...state,
			}
    }
}