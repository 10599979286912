import { MultipleDisplay } from "../MultipleDisplayInt";

export class Name implements MultipleDisplay {
    public id!:number;
	
	public fullName!:string;
	public surname!:string;
	public givenName!:string;
	public fatherName!:string;
	public motherName!:string;
	public normalized!:string;

	public get fullNameForDisplay() : string {
		if (this.fullName && this.fullName.length>0) {
			return this.fullName;
		}

		let fullNameConcat:string = '';

		if (this.surname) {
			fullNameConcat+=this.surname;
			fullNameConcat+=' ';
		}

		if (this.fatherName) {
			fullNameConcat+=this.fatherName;
			fullNameConcat+=' ';
		}

		if (this.givenName) {
			fullNameConcat+=this.givenName;
		}

		return fullNameConcat.trim();
	}

	displayAttr () {   
        return this.normalized;
    }
}