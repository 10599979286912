import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { Request } from "../../model/common/Request";
import { saveGlobalCalcRiskConfiguration, updateGlobalCalcRiskConfiguration } from "../../store/risk/action";
import { RequestDataObject } from "../../model/common/RequestDataObject";
import { RiskGlobalScoreCategory } from "../../model/risk/RiskGlobalScoreCategory";

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,    
    create?:boolean,
    scenario?:string;
    closePopup?:() => void,
    screenakey?:string,
    riskGlobalScoreCategory?:RiskGlobalScoreCategory
}

interface IntState {
    category:string,  
    weight:string,    
    operation:string,    
    errors:{
        category:string,
        weight:string,
        operation:string
    }
}

class RiskGlobalCalculationDetailsPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {
            category:'',  
            weight:'1',    
            operation:'',    
            errors:{
                category:'',
                weight:'',
                operation:''
            }    
        }
    }

    public componentWillReceiveProps(nextProps: IntProps) {
        if (nextProps.create) {
            this.setState({
                category:'',  
                weight:'1',    
                operation:'',    
                errors:{
                    category:'',
                    weight:'',
                    operation:''
            } 
            })
        } else if (nextProps.riskGlobalScoreCategory) {             
            this.setState({
                    category : nextProps.riskGlobalScoreCategory.category,
                    weight : String(nextProps.riskGlobalScoreCategory.weight),
                    operation : nextProps.riskGlobalScoreCategory.operation,
                    errors:{
                        category:'',
                        weight:'',
                        operation:''
                    }
            })
        }
           
      }

    public render() {    

       

        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "d-modal" : "c-popover c-lc-popover js-lc-context"} style={{ width: "50rem", height: "40rem" }}>
                    <div className="d-modal__view js-modal-view">
                        <div className="d-modal__head">
                            <legend className="d-modal__title">
                                {formatMessage('alert_details_information_title')}
                            </legend>
                            <div>                               
                                <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                    <svg className="o-icon o-icon--close o-icon--prepended">
                                        <title>{IconsEnum.CLOSE.title}</title>
                                        <use href={IconsEnum.CLOSE.url} />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="d-modal__view js-modal-view" style={{ margin: "1rem" }}>
                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_category')}
                                </label>
                                <input
                                    className="o-input"
                                    name="category"
                                    placeholder={formatMessage('risk_conf_category')}
                                    value={this.state.category}
                                    onChange={this.changeCategory}
                                    style={{ marginTop: '2px' }}
                                />
                                 {this.state.errors && this.state.errors["category"] &&
                                    <span className="form-error">{this.state.errors["category"]}</span>
                                 }
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_operation')}
                                </label>
                                <input
                                    className="o-input"
                                    name="operation"
                                    placeholder={formatMessage('risk_conf_operation')}
                                    value={this.state.operation}
                                    onChange={this.changeOperation}
                                    style={{ marginTop: '2px' }}
                                />
                                 {this.state.errors && this.state.errors["operation"] &&
                                    <span className="form-error">{this.state.errors["operation"]}</span>
                                 }
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_weight')}
                                </label>
                                <input
                                    className="o-input"
                                    name="weight"
                                    placeholder={formatMessage('risk_conf_weight')}
                                    value={this.state.weight}
                                    onChange={this.changeWeight}
                                    style={{ marginTop: '2px' }}
                                />
                                {this.state.errors && this.state.errors["weight"] &&
                                    <span className="form-error">{this.state.errors["weight"]}</span>
                                 } 
                            </div>

                                                  
                        </div>

                        <div style={{display : 'flex'}}>
                                <button className="m-button m-button--small-margin-bottom" onClick={this.props.closePopup} style={{margin: '5px'}}>
                                    {formatMessage('button_cancel')}
                                </button> 
                                
                                <button className="m-button m-button--small-margin-bottom" onClick={this.saveRiskCategory} style={{margin: '5px'}}>
                                    {formatMessage('button_save')}
                                </button> 
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private changeCategory = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            category: event.target.value
        });
    }
    
    private changeOperation = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            operation: event.target.value
        });
    }

    private changeWeight = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            weight: event.target.value
        });
    }

    private saveRiskCategory = () : void => {
        let request:Request = new Request();
        request.queries = [];
        let requestDataObj : RequestDataObject = new RequestDataObject();
        
        if (this.props.create) {
            requestDataObj.riskGlobalScoreCategory = new RiskGlobalScoreCategory(); 
            requestDataObj.riskGlobalScoreCategory.category = this.state.category;
            requestDataObj.riskGlobalScoreCategory.operation = this.state.operation;
            requestDataObj.riskGlobalScoreCategory.weight = Number(this.state.weight);

            request.queries.push(requestDataObj);
            if (this.props.scenario) {
                saveGlobalCalcRiskConfiguration(request, this.props.scenario, this.props.screenakey);
            }           
        } else if (this.props.riskGlobalScoreCategory) {
            requestDataObj.riskGlobalScoreCategory = this.props.riskGlobalScoreCategory;
            requestDataObj.riskGlobalScoreCategory.category = this.state.category;
            requestDataObj.riskGlobalScoreCategory.operation = this.state.operation;
            requestDataObj.riskGlobalScoreCategory.weight = Number(this.state.weight);

            request.queries.push(requestDataObj);
            if (this.props.scenario) {
                updateGlobalCalcRiskConfiguration(request, this.props.screenakey);
            }  
        }

        if (this.props.closePopup) {
            this.props.closePopup();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    screenakey : state.loginStore.screenaapikey 
})

export const RiskGlobalCalculationDetailsPopup = connect(mapStateToProps, null)(RiskGlobalCalculationDetailsPopupClass);