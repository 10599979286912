import { Name } from "../../model/persons/Name";
import { Person } from "../../model/persons/Person";
import { MatchingName } from "../../model/screena/MatchingName";

export function getPersonName(person:Person | undefined) : string {
    if (!person) {
        return "";
    }
    if (person.names && person.names.length>0) {
        if (person.names[0].fullName) {
            return person.names[0].fullName;
        }

        let nameToDisplay: string = '';
        if (person.names[0].surname) {
            nameToDisplay+=person.names[0].surname;
        }

        if (person.names[0].givenName) {
            if (nameToDisplay.length>0) {
                nameToDisplay+=' ';
            }
            nameToDisplay+=person.names[0].givenName;
        }

        return nameToDisplay;
    }

    return '';
}

export function getPersonNameFromName(names:Name  | MatchingName | any) : string {
    if (names) {
        
        if (names.fullName) {
            return names.fullName;
        }

        let nameToDisplay: string = '';
        if (names.givenName) {            
            nameToDisplay+=names.givenName;
        }

        if (names.fatherName) {
            if (nameToDisplay.length>0) {
                nameToDisplay+=' ';
            }
            nameToDisplay+=names.fatherName;
        }

        if (names.surname) {
            if (nameToDisplay.length>0) {
                nameToDisplay+=' ';
            }
            nameToDisplay+=names.surname;
        }
        return nameToDisplay;
    }

    return '';
}

export function getPersonNameFromMatchingName(names:MatchingName | undefined) : string {
    if (names) {
        if (names.fullName) {
            return names.fullName;
        }

        let nameToDisplay: string = '';

        if (names.givenName) {            
            nameToDisplay+=names.givenName;
        }

        if (names.fatherName) {
            if (nameToDisplay.length>0) {
                nameToDisplay+=' ';
            }
            nameToDisplay+=names.fatherName;
        }

        if (names.surname) {
            if (nameToDisplay.length>0) {
                nameToDisplay+=' ';
            }
            nameToDisplay+=names.surname;
        }

        return nameToDisplay;
    }

    return '';
}