import { User } from "../../model/user/User"
import { UserDashBoardPreferences } from "../../model/user/UserDashBoardPreferences"
import { Constants } from "./constant"
import { UserStore } from "./type"


const init: UserStore = {
    currentUser: new User(),
    listOfUsers: [],
    fullListOfUsers: [],
    userDashBoardPreferences: new UserDashBoardPreferences(),
    amountOfUsers: -1
} 
    
export const userReducer = (state: UserStore = init, action: any): UserStore => {    
    switch (action.type) {
        case Constants.SINGLEUSER:            
            return {
				...state,
				currentUser: action.payload.user
            } 

        case Constants.LISTOFUSERS:            
            return {
				...state,
				listOfUsers: action.payload.users
            } 

        case Constants.FULLLISTOFUSERS:
            return {
				...state,
				fullListOfUsers: action.payload.users
            } 

        case Constants.COUNTOFUSERS:            
            return {
				...state,
				amountOfUsers: action.payload.numberOfObjects
            }  

        case Constants.USERDASHBOARDPREFERENCES:
            return {
                ...state,
                userDashBoardPreferences: action.payload.userDashBoardPreferences
            }

        default:
            return {
				...state,
			}
    }
}