export class MatchingIdDoc {

    public type!:String;	
	public country!:String;	
	public number!:String;	
    public description!:String;
    	
	public issuedBy!:String;	
	public issuingCountry!:String;	
	public issueDate!:String;	
	public authority!:String;
	public expiryDate!:String;

}

