export class SearchPairValue {

    public id!:number;
	
	public field!:string;
	public valueStr!:string;
	public valueDate1!:string;
	public valueDate2!:string;
	public listOfValueStr!:string[];
	public listOfValueInt!:number[];
	
}