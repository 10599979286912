import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/attribute.css'
import { formatMessage } from "../../common/translate/Translate";
import { Person } from "../../model/persons/Person";
import { IRootState } from "../../store";
import { AttributeDisplay } from "../../components/attribute/AttributeDisplay";
import { MultipleAttributeDisplay } from "../../components/attribute/MultipleAttributeDisplay";
import { MultipleAttributeDisplayName } from "../../components/attribute/MultipleAttributeDisplayName";

interface IntProps {
    person?:Person
}

interface IntState {
    
}

export class CustomerDetailsClass extends React.Component<IntProps, IntState> {

     public render() {    
        return ( 
                <React.Fragment>   
                    {this.props.person &&
                        <div className="d-modal__view js-modal-view">
                            <div className={`entity-card js-horizontal-card`}>
                                <div className="entity-card__data" style={{marginLeft:'2rem'}}>
                                    <div>
                                        <p className="row-card-paragraph" style={{fontWeight: 'bold'}}>
                                            {this.props.person?this.props.person.names?this.props.person.names[0].fullName:'':''}
                                        </p>                           
                                    </div>                                            
                                    <p className="row-card-paragraph">
                                        <span className={"o-badge o-badge--small"}>
                                            {this.props.person?this.props.person.dataset?this.props.person.dataset.label:'':''}                               
                                        </span>                      
                                    </p>
                                    <p className="row-card-paragraph">
                                        <span className={"o-badge o-badge--small"}>                               
                                            {this.props.person?this.props.person.entityType:''}  
                                        </span>                      
                                    </p>
                                </div>                    
                            </div>

                            <div className="match-display">
                                <div className="attribute">
                                    <AttributeDisplay field={formatMessage('person_details_dataid')} 
                                                        value={this.props.person?this.props.person.dataID:''}/>
                                    <MultipleAttributeDisplayName field={formatMessage('person_details_fullName')} 
                                                        value={this.props.person?this.props.person.names:[]}
                                                        />
                                    <MultipleAttributeDisplay field={formatMessage('person_details_dob')} 
                                                        value={this.props.person?this.props.person.datesOfBirth:[]}/>
                                    <MultipleAttributeDisplay field={formatMessage('person_details_nationality')} 
                                                        value={this.props.person?this.props.person.nationalities:[]}/>
                                    <MultipleAttributeDisplay field={formatMessage('person_details_pobs')} 
                                                        value={this.props.person?this.props.person.placesOfBirth:[]}/>
                                    <MultipleAttributeDisplay field={formatMessage('person_details_idDoc')} 
                                                        value={this.props.person?this.props.person.identityDocuments:[]} identificationDocument={true}/>
                                </div>
                                <div className="attribute">
                                    <AttributeDisplay field={formatMessage('person_details_dataid')} 
                                                        value={this.props.person?this.props.person.dataID:''}/>
                                </div>
                            </div>
                        </div>  
                    }                  
                </React.Fragment>
        );
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    person : state.personStore.currentPerson
})

export const CustomerDetails = connect(mapStateToProps, null)(CustomerDetailsClass);