import { Account } from "../account/Account";
import { Alert } from "../alerts/Alert";
import { Person } from "../persons/Person";

export class PaginationRequest {
    public pageNumber!:number;
	public maxPerPage!:number;	

	public calculateSearchStats!:boolean;

	public keyword!:string;
	
	public sortField!:string;
	public sortOrder!:string;

	public fromParentFolder!:number;
	
	public alert!:Alert;
	public person!:Person;
	public account!:Account;

	public dateRangeField!:string;
	public date1!:string;
	public date2!:string;

	public numberRangeField!:string;
	public number1!:number;
	public number2!:number;

	public userFilter!:number[];
	public groupFilter!:number[];
}