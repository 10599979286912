export class Group {
    public id!:number;

    public name!:string;
	public email!:string;
	public description!:string;
	
	public creationDate!:string;
	public createdBy!:string;
	public updatedDate!:string;
	public updatedBy!:string;

	public deleted!:boolean;
}