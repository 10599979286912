import { Constants } from "./constant"
import { TransactionStore } from "./type"


const init: TransactionStore = {
    fullListOfTransactions:[],
    listOfTransactions : [],
    amountOfTransactions : -1
} 
    
export const transactionReducer = (state: TransactionStore = init, action: any): TransactionStore => {    
    switch (action.type) {      
        case Constants.LISTOFTRXNS:          
            return {
				...state,
				fullListOfTransactions: action.payload.transactions
            } 

        case Constants.PAGELISTOFTRXNS:          
            return {
				...state,
				listOfTransactions: action.payload.transactions
            }

        case Constants.COUNTOFTRXNS:            
            return {
				...state,
				amountOfTransactions: action.payload.numberOfObjects
            }  

        default:
            return {
				...state,
			}
    }
}