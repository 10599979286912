import React from "react";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { connect } from "react-redux";
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { Comment } from "../../model/alerts/Comment";
import { Request } from "../../model/common/Request";
import { RowCardComment } from "../../components/comment/RowCardComment";
import { Person } from "../../model/persons/Person";
import { addCommentToPerson } from "../../store/customer/action";

interface IntProps {
    person?:Person
}

interface IntState {
    commentValue:string;
}

export class CustomerCommentPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {
            commentValue : ''
        }
    }

     public render() {    
        return (
                <React.Fragment>   
                    <div className="d-modal__view js-modal-view" style={{width: '100%'}}>
                         <textarea
                               className="o-input"
                               name="comment"
                               placeholder={formatMessage('comment_text')}    
                               onChange={this.addComment}    
                               value={this.state.commentValue}                      
                               style={{margin: '5px', width:'98%'}}              
                          /> 
                        <div style={{display : 'flex', paddingRight : '22px'}}>
                            <button className="m-button m-button--small-margin-bottom" style={{margin: '5px'}} onClick={this.onSubmit}>
                                {formatMessage('button_save')}
                            </button>                                
                        </div>

                        <div style={{overflowY:'auto', overflowX:'hidden'}}>
                            {this.props.person && this.props.person.comments && this.props.person.comments.map((comment, j) => { 
                                    return (
                                        <RowCardComment comment={comment}/>
                                    );
                                }
                            )}                     
                        </div>
                    </div>
                   
                </React.Fragment>
        );
    }

    private addComment = (event:any) => {
        this.setState({
            ...this.state,
            commentValue : event.target.value
        });    
    }

    private onSubmit = () => {
        if (this.props.person) {
            let comment:Comment = new Comment();
            comment.comment = this.state.commentValue;

            let request:Request = new Request();
            request.person = this.props.person;
            request.comment = comment;

            addCommentToPerson(request);

            this.setState({
                ...this.state,
                commentValue : ''
            }); 
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,    
    person : state.personStore.currentPerson,
})

export const CustomerCommentPopup = connect(mapStateToProps, null)(CustomerCommentPopupClass);